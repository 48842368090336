import axios from 'axios'

const apiUrl = process.env.VUE_APP_API_URL

const state = () => ({
  managersList: null,
})
const getters = {}
const mutations = {
  setData (state, data) {
    state[data.label] = data.data
  }
}
const actions = {
  getAllManagersList ({ commit }, {token}) {

    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/admin/managers`,
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(resp => {
          commit('setData', { label: 'managersList', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
