<template>
    <div class="outside-link" @click="addAgent">
        <div class="outside-link__content">
            Вступить в партнерскую программу
        </div>
    </div>
</template>

<script>
export default {
    name: "CreateReferral",
    data() {
        return { }
    },
    methods: {
        addAgent() {
            this.$store.commit('Dialog/setData', { label: 'isEditable', data: false })
            this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'ReferralData' })
            this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
        },
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";

.outside-link {
    // width: 100%;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid $primary;
    border-radius: 4px;
    &:hover {
        cursor: pointer;
    }

    &__content {
        display: flex;
        flex-direction: column;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: normal;
        color: $primary;
    }
}
</style>