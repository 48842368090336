<template>
  <div class="registration-page" >
    <div v-if="locale==='RU'">
      <!-- <FormElement v-if="!isRegister" :title="$t('registration.title')" v-loading="loading"> -->
      <FormElement v-if="!phoneVerified || !showSendVerify" :title="$t('registration.title')" v-loading="loading">
        <div
          class="form-input"
          :class="
          [
            {'form-input--full' : form.email},
            {'form-input--error' : errors.email || (authErrors && authErrors.email)}
          ]"
        >
          <input v-model="form.email" type="text" required>
          <span class="form-input__label">
            {{$t('email')}}
          </span>
          <div v-if="errors.email" class="form-input__error" v-text="errors.email" />
          <div v-if="authErrors && authErrors.email" class="form-input__error">
            <p v-for="(err, index) in authErrors.email" :key="index">
              {{ err }}
            </p>
          </div>
        </div>
        <div 
          v-if="phoneSend || locale === 'USA'"
          class="form-input" 
          :class="
          [
            {'form-input--full' : form.phone},
            {'form-input--error' : errors.phone || (authErrors && authErrors.phone)}
          ]"
        >
          <input v-if="locale==='RU'" :value="getPhone" @input="getPhone = $event.target.value" :disabled="phoneSend" type="tel" required>
          <input v-if="locale==='USA'" v-model="form.phone" type="tel" v-mask="'+############'" required>
          <span class="form-input__label">{{$t('phone')}} </span>
          <div v-if="phoneVerified" class="registration-page__phone-verified-icon">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.5402 2.03515C8.87897 2.28222 6.41222 3.53417 4.64172 5.53715C2.87219 7.54007 1.93272 10.1426 2.01472 12.8134C2.09772 15.4854 3.19539 18.0244 5.08497 19.9149C6.97559 21.8045 9.51472 22.9021 12.1865 22.9851C14.8575 23.0672 17.46 22.1277 19.4627 20.3581C21.4656 18.5876 22.7177 16.1209 22.9647 13.4596C23.2421 10.3531 22.1278 7.2829 19.9227 5.0769C17.7167 2.87182 14.6465 1.75765 11.54 2.0349L11.5402 2.03515ZM18.1477 10.1329L11.925 16.2901C11.5373 16.6681 10.9181 16.6681 10.5304 16.2901L6.86244 12.7871C6.46399 12.4043 6.45034 11.7705 6.83412 11.3711C7.21692 10.9727 7.85072 10.959 8.25015 11.3428L11.2101 14.1826L16.7501 8.7098C17.1456 8.3387 17.7648 8.34945 18.1466 8.73617C18.5275 9.12192 18.5314 9.74202 18.1554 10.1326L18.1477 10.1329Z" fill="#219653"/>
            </svg>
          </div>
          <div v-if="errors.phone" class="form-input__error" v-text="errors.phone" />
          <div v-if="authErrors && authErrors.phone" class="form-input__error">
            <p v-for="(err, index) in authErrors.phone" :key="index">
              {{ err }}
            </p>
          </div>
        </div>
        <div v-if="locale==='RU' && (!phoneVerified && !phoneSend)" class="registration-page__phone-row">
          <div>
            <div class="form-input-width-country-select">
              <div class="country-select-wrapper">
                <el-select 
                  v-model="countryValue" 
                  placeholder="Страна"
                  popperClass="country-select"
                  @change="selectCountry"
                  >
                  <template v-slot:prefix>
                    <img class="country-select__flag" :src="flagImagePath" alt="flag-icon">
                  </template>
                    <el-option
                      v-for="item in countryOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      >
                    </el-option>  
                </el-select>
              </div>
              <div
                :key="'phone'"
                class="form-input" 
                :class="
                [
                  {'form-input--full' : getPhone},
                  {'form-input--error' : errors.phone || (authErrors && authErrors.phone) || authErrors}
                ]"
              >
                    <input ref="phone" :value="getPhone" @input="getPhone = $event.target.value" type="tel" v-mask="phoneMask" required :disabled="phoneSend">
                    <span class="form-input__label">{{$t('phone')}} </span>
                    <!-- <div v-if="errors.phone" class="form-input__error" v-text="errors.phone" />
                    <div v-if="authErrors && authErrors.phone" class="form-input__error">
                      <p v-for="(err, index) in authErrors.phone" :key="index">
                        {{ err }}
                      </p>
                    </div> -->
                
                <!-- <input v-model="form.phone" type="tel" v-mask="'+7(###)###-##-##'" required :disabled="phoneSend">
                <span class="form-input__label">{{$t('phone')}} </span>
                <div v-if="errors.phone" class="form-input__error" v-text="errors.phone" />
                <div v-if="authErrors && authErrors.phone" class="form-input__error">
                  <p v-for="(err, index) in authErrors.phone" :key="index">
                    {{ err }}
                  </p>
                </div> -->
              </div>
            </div>
            <!-- <div>
              <ButtonElement
                :text="$t('button_props.confirm')"
                @click-button="sendPhone"
              />
            </div> -->
          </div>
          <div v-if="errors.phone" class="form-input__error" v-text="errors.phone" />
          <div v-if="authErrors && authErrors.phone" class="form-input__error">
              <p v-for="(err, index) in authErrors.phone" :key="index">
                {{ err }}
              </p>
          </div>
          <div v-if="phoneSend && smsTimer" class="registration-page__phone-row-message">{{ $t('registration.resendCodeTimeout') }} <span>{{ smsTimerCount }}</span> {{ $t('registration.resendCodeTimeout2') }}</div>
          <div v-if="phoneSend && !smsTimer" class="registration-page__phone-row-message is-button" @click="sendPhone('resend')">{{ $t('registration.resendCode') }}</div>
        </div>
        
        <div v-if="locale==='RU'"
          class="form-input"
          :class="
          [
            {'form-input--full' : form.password},
            {'form-input--error' : errors.password || (authErrors && authErrors.password)}
          ]"
        >
          <input
            v-model="form.password"
            :type="isHidePassword ? 'password' : 'text'"
            @focus="checkingPassword = true"
            @blur="checkingPassword = false"
          >
          <span class="form-input__label">
            {{$t('password')}}
          </span>
          <div class="form-input__icon" @click="isHidePassword=!isHidePassword">
            <img v-if="isHidePassword" src="@/assets/icons/icon-eye-closed.svg">
            <img v-else src="@/assets/icons/icon-eye.svg">
          </div>
          <div v-if="errors.password" class="form-input__error" v-text="errors.password" />
          <div v-if="authErrors && authErrors.password" class="form-input__error">
            <p v-for="(err, index) in authErrors.password" :key="index">
              {{ err }}
            </p>
          </div>
          <div v-if="checkingPassword" class="form-input__info">
            <div
              class="form-input__info-item"
              :class="{'form-input__info-item--success' : form.password && form.password.length > 5}"
            >
              {{$t('registration.minimum_symbols')}}
            </div>
            <div
              class="form-input__info-item"
              :class="{'form-input__info-item--success' : isContainNumber}"
            >
              {{$t('registration.minimum_numbers')}}
            </div>
            <div
              class="form-input__info-item"
              :class="{'form-input__info-item--success' : isContainLetter}"
            >
              {{$t('registration.minimum_letters')}}
            </div>
            <div
              class="form-input__info-item"
              :class="{'form-input__info-item--success' : isContainBigLetter}"
            >
            {{$t('registration.minimum_capital_letters')}}
            </div>
          </div>
        </div>
        <div v-if="locale==='USA'"
          class="form-input"
          :class="
          [
            {'form-input--full' : form.password},
            {'form-input--error' : errors.password || (authErrors && authErrors.password)}
          ]"
        >
          <input
            v-model="form.password"
            :type="isHidePassword ? 'password' : 'text'"
            @focus="checkingPassword = true"
            @blur="checkingPassword = false"
          >
          <span class="form-input__label">
            {{ $t('registration.password') }}
          </span>
          <div class="form-input__icon" @click="isHidePassword=!isHidePassword">
            <img v-if="isHidePassword" src="@/assets/icons/icon-eye-closed.svg">
            <img v-else src="@/assets/icons/icon-eye.svg">
          </div>
          <div v-if="errors.password" class="form-input__error" v-text="errors.password" />
          <div v-if="authErrors && authErrors.password" class="form-input__error">
            <p v-for="(err, index) in authErrors.password" :key="index">
              {{ err }}
            </p>
          </div>
          <div v-if="checkingPassword" class="form-input__info">
            <div
              class="form-input__info-item"
              :class="{'form-input__info-item--success' : form.password && form.password.length > 5}"
            >
              {{ $t('registration.minimum_symbols') }}
            </div>
            <div
              class="form-input__info-item"
              :class="{'form-input__info-item--success' : isContainNumber}"
            >
              {{ $t('registration.minimum_numbers') }}
            </div>
            <div
              class="form-input__info-item"
              :class="{'form-input__info-item--success' : isContainLetter}"
            >
              {{ $t('registration.minimum_letters') }}
            </div>
            <div
              class="form-input__info-item"
              :class="{'form-input__info-item--success' : isContainBigLetter}"
            >
              {{ $t('registration.minimum_capital_letters') }}
            </div>
          </div>
        </div>
        <div
          class="form-input"
          :class="
          [
            {'form-input--full' : form.password_confirmation},
            {'form-input--error' : errors.password_confirmation || (authErrors && authErrors.password_confirmation)}
          ]"
        >
          <input v-model="form.password_confirmation" :type="isHidePassword ? 'password' : 'text'">
          <span class="form-input__label">
            {{$t('registration.repeat_password')}}
          </span>
          <div v-if="errors.password_confirmation" class="form-input__error" v-text="errors.password_confirmation" />
          <div v-if="authErrors && authErrors.password_confirmation" class="form-input__error">
            <p v-for="(err, index) in authErrors.password_confirmation" :key="index">
              {{ err }}
            </p>
          </div>
        </div>
    
        <div>
          <div class="registration-page__types">
            <label class="form-control">
              <input type="radio" :value="0" v-model="form.type">
              {{$t('registration.individual')}}
            </label>
            <label class="form-control">
              <input type="radio" :value="1" v-model="form.type">
              {{$t('registration.legal')}}
            </label>
          </div>
          <div v-if="errors.type" class="form-input__error" v-text="errors.type" />
        </div>
        <div class="registration-page__agree">
          <label class="input__item-checkbox">
            <input
                type="checkbox"
                value="'all'"
                required
                v-model="form.isAgreeAdds"
            >
            <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
            <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{$t('registration.agree_ads')}}
                <a href="https://profitads.ru/soglasie-na-poluchenie-reklamy/" target="_blank">{{$t('registration.get_adds')}}</a>
              </span>
            </span>
          </label>
          <label class="input__item-checkbox">
            <input
                type="checkbox"
                value="'all'"
                required
                v-model="form.isAgreePolicy"
            >
            <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
            <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{$t('registration.agree_with')}}
                <a href="https://profitads.ru/privacy-policy" target="_blank">{{$t('registration.privacy_policy')}}</a>
                {{$t('registration.and')}}
                <a href="https://profitads.ru/agreement" target="_blank">{{$t('registration.user_agreement')}}</a>
              </span>
            </span>
          </label>
          <!-- <label class="input__item-checkbox">
            <input
                type="checkbox"
                value="'all'"
                required
                v-model="form.isAgreeAgreement"
            >
            <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
            <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{$t('registration.agree_with')}}
                <a href="https://profitads.ru/agreement/" target="_blank">{{$t('registration.user_agreement')}}</a>
              </span>
            </span>
          </label> -->
          <div v-if="errors.isAgree" class="form-input__error" v-text="errors.isAgree" />
        </div>
        <div v-if="locale==='RU' && (phoneSend && !phoneVerified)" class="registration-page__phone-row">
          <div class="registration-page__phone-row-inner">
            <div>
              <div 
                v-if="phoneSend"
                :key="'smsCode'"
                class="form-input" 
                :class="
                [
                  {'form-input--full' : phoneSmsCode}
                ]"
              >
                <input v-model="phoneSmsCode" type="text">
                <span class="form-input__label">{{$t('smsCode')}} </span>
                <div v-if="errors.phoneSmsCode" class="form-input__error" v-text="errors.phoneSmsCode" />
              </div>
            </div>
            <div>
              <ButtonElement
                v-if="phoneSend"
                :text="$t('button_props.confirm')"
                @click-button="checkSmsCode"
              />
            </div>
          </div>
          <div v-if="phoneSend && smsTimer" class="registration-page__phone-row-message">{{ $t('registration.resendCodeTimeout') }} <span>{{ smsTimerCount }}</span> {{ $t('registration.resendCodeTimeout2') }}</div>
          <div v-if="phoneSend && !smsTimer" class="registration-page__phone-row-message is-button" @click="sendPhone('resend')">{{ $t('registration.resendCode') }}</div>
        </div>
        <ButtonElement
          v-if="!phoneSend && !isRegister"
          :text="$t('login.next')"
          @click-button="register"
        />
        <ButtonElement
          v-if="isRegister"
          :text="$t('login.register')"
          :disabled="locale === 'RU' && !phoneVerified"
          @click-button="showVerify"
        
        />
        <div class="registration-page__link-auth">
          {{$t('registration.have_account')}}
          <router-link :to="{name: 'Login'}">{{$t('registration.log_in')}}</router-link>
        </div>
      </FormElement>
      <!-- <div v-if="isRegister" class="registration-page__notice"> -->
      <div v-if="phoneVerified && showSendVerify" class="registration-page__notice">
        <div class="registration-page__notice-title">
          {{$t('registration.notice_title')}}
        </div>
        <div class="registration-page__notice-text">
          {{$t('registration.verification')}} <span v-if="isNotice">{{$t('registration.repeat')}}</span>{{$t('registration.verification_text')}} 
        </div>
        <router-link
          class="registration-page__notice-link"
          :class="{'locale-usa': locale==='USA'}"
          :to="{name: 'Login'}"
        >
          {{$t('registration.log_in')}}
        </router-link>
        <div class="registration-page__notice-repeat" @click="repeatVerify">
          {{$t('registration.send_again')}}
        </div>
      </div>
    </div>
    <div v-if="locale==='USA'">
      <FormElement v-if="!isRegister" :title="$t('registration.title')" v-loading="loading">
      <div
        class="form-input"
        :class="
        [
          {'form-input--full' : form.email},
          {'form-input--error' : errors.email || (authErrors && authErrors.email)}
        ]"
      >
        <input v-model="form.email" type="text" required>
        <span class="form-input__label">
          {{$t('email')}}
        </span>
        <div v-if="errors.email" class="form-input__error" v-text="errors.email" />
        <div v-if="authErrors && authErrors.email" class="form-input__error">
          <p v-for="(err, index) in authErrors.email" :key="index">
            {{ err }}
          </p>
        </div>
      </div>
      <div 
        v-if="phoneSend || locale === 'USA'"
        class="form-input" 
        :class="
        [
          {'form-input--full' : form.phone},
          {'form-input--error' : errors.phone || (authErrors && authErrors.phone)}
        ]"
      >
        <input v-if="locale==='RU'" v-model="form.phone" type="tel" v-mask="'+7(###)###-##-##'" required :disabled="phoneSend">
        <input v-if="locale==='USA'" v-model="form.phone" type="tel" v-mask="'+############'" required>
        <span class="form-input__label">{{$t('phone')}} </span>
        <div v-if="phoneVerified" class="registration-page__phone-verified-icon">
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5402 2.03515C8.87897 2.28222 6.41222 3.53417 4.64172 5.53715C2.87219 7.54007 1.93272 10.1426 2.01472 12.8134C2.09772 15.4854 3.19539 18.0244 5.08497 19.9149C6.97559 21.8045 9.51472 22.9021 12.1865 22.9851C14.8575 23.0672 17.46 22.1277 19.4627 20.3581C21.4656 18.5876 22.7177 16.1209 22.9647 13.4596C23.2421 10.3531 22.1278 7.2829 19.9227 5.0769C17.7167 2.87182 14.6465 1.75765 11.54 2.0349L11.5402 2.03515ZM18.1477 10.1329L11.925 16.2901C11.5373 16.6681 10.9181 16.6681 10.5304 16.2901L6.86244 12.7871C6.46399 12.4043 6.45034 11.7705 6.83412 11.3711C7.21692 10.9727 7.85072 10.959 8.25015 11.3428L11.2101 14.1826L16.7501 8.7098C17.1456 8.3387 17.7648 8.34945 18.1466 8.73617C18.5275 9.12192 18.5314 9.74202 18.1554 10.1326L18.1477 10.1329Z" fill="#219653"/>
          </svg>
        </div>
        <div v-if="errors.phone" class="form-input__error" v-text="errors.phone" />
        <div v-if="authErrors && authErrors.phone" class="form-input__error">
          <p v-for="(err, index) in authErrors.phone" :key="index">
            {{ err }}
          </p>
        </div>
      </div>
      <div v-if="locale==='RU' && !phoneVerified" class="registration-page__phone-row">
        <div class="registration-page__phone-row-inner">
          <div>
            <div 
              v-if="phoneSend"
              :key="'smsCode'"
              class="form-input" 
              :class="
              [
                {'form-input--full' : phoneSmsCode}
              ]"
            >
              <input v-model="phoneSmsCode" type="text">
              <span class="form-input__label">{{$t('smsCode')}} </span>
              <div v-if="errors.phoneSmsCode" class="form-input__error" v-text="errors.phoneSmsCode" />
            </div>

            <div 
              v-else
              :key="'phone'"
              class="form-input" 
              :class="
              [
                {'form-input--full' : form.phone},
                {'form-input--error' : errors.phone || (authErrors && authErrors.phone)}
              ]"
            >
              <input v-model="form.phone" type="tel" v-mask="'+7(###)###-##-##'" required>
              <span class="form-input__label">{{$t('phone')}} </span>
              <div v-if="errors.phone" class="form-input__error" v-text="errors.phone" />
            </div>
          </div>
          <div>
            <ButtonElement
              v-if="phoneSend"
              :text="$t('button_props.confirm')"
              @click-button="checkSmsCode"
            />
            <ButtonElement
              v-else
              :text="$t('button_props.confirm')"
              @click-button="sendPhone"
            />
   
          </div>
        </div>
        <div v-if="phoneSend && smsTimer" class="registration-page__phone-row-message">{{ $t('registration.resendCodeTimeout') }} <span>{{ smsTimerCount }}</span> {{ $t('registration.resendCodeTimeout2') }}</div>
        <div v-if="phoneSend && !smsTimer" class="registration-page__phone-row-message is-button" @click="sendPhone('resend')">{{ $t('registration.resendCode') }}</div>
      </div>
      
      <div v-if="locale==='RU'"
        class="form-input"
        :class="
        [
          {'form-input--full' : form.password},
          {'form-input--error' : errors.password || (authErrors && authErrors.password)}
        ]"
      >
        <input
          v-model="form.password"
          :type="isHidePassword ? 'password' : 'text'"
          @focus="checkingPassword = true"
          @blur="checkingPassword = false"
        >
        <span class="form-input__label">
          {{$t('password')}}
        </span>
        <div class="form-input__icon" @click="isHidePassword=!isHidePassword">
          <img v-if="isHidePassword" src="@/assets/icons/icon-eye-closed.svg">
          <img v-else src="@/assets/icons/icon-eye.svg">
        </div>
        <div v-if="errors.password" class="form-input__error" v-text="errors.password" />
        <div v-if="authErrors && authErrors.password" class="form-input__error">
          <p v-for="(err, index) in authErrors.password" :key="index">
            {{ err }}
          </p>
        </div>
        <div v-if="checkingPassword" class="form-input__info">
          <div
            class="form-input__info-item"
            :class="{'form-input__info-item--success' : form.password && form.password.length > 5}"
          >
            {{$t('registration.minimum_symbols')}}
          </div>
          <div
            class="form-input__info-item"
            :class="{'form-input__info-item--success' : isContainNumber}"
          >
            {{$t('registration.minimum_numbers')}}
          </div>
          <div
            class="form-input__info-item"
            :class="{'form-input__info-item--success' : isContainLetter}"
          >
            {{$t('registration.minimum_letters')}}
          </div>
          <div
            class="form-input__info-item"
            :class="{'form-input__info-item--success' : isContainBigLetter}"
          >
           {{$t('registration.minimum_capital_letters')}}
          </div>
        </div>
      </div>
      <div v-if="locale==='USA'"
        class="form-input"
        :class="
        [
          {'form-input--full' : form.password},
          {'form-input--error' : errors.password || (authErrors && authErrors.password)}
        ]"
      >
        <input
          v-model="form.password"
          :type="isHidePassword ? 'password' : 'text'"
          @focus="checkingPassword = true"
          @blur="checkingPassword = false"
        >
        <span class="form-input__label">
          {{ $t('registration.password') }}
        </span>
        <div class="form-input__icon" @click="isHidePassword=!isHidePassword">
          <img v-if="isHidePassword" src="@/assets/icons/icon-eye-closed.svg">
          <img v-else src="@/assets/icons/icon-eye.svg">
        </div>
        <div v-if="errors.password" class="form-input__error" v-text="errors.password" />
        <div v-if="authErrors && authErrors.password" class="form-input__error">
          <p v-for="(err, index) in authErrors.password" :key="index">
            {{ err }}
          </p>
        </div>
        <div v-if="checkingPassword" class="form-input__info">
          <div
            class="form-input__info-item"
            :class="{'form-input__info-item--success' : form.password && form.password.length > 5}"
          >
            {{ $t('registration.minimum_symbols') }}
          </div>
          <div
            class="form-input__info-item"
            :class="{'form-input__info-item--success' : isContainNumber}"
          >
            {{ $t('registration.minimum_numbers') }}
          </div>
          <div
            class="form-input__info-item"
            :class="{'form-input__info-item--success' : isContainLetter}"
          >
            {{ $t('registration.minimum_letters') }}
          </div>
          <div
            class="form-input__info-item"
            :class="{'form-input__info-item--success' : isContainBigLetter}"
          >
            {{ $t('registration.minimum_capital_letters') }}
          </div>
        </div>
      </div>
      <div
        class="form-input"
        :class="
        [
          {'form-input--full' : form.password_confirmation},
          {'form-input--error' : errors.password_confirmation || (authErrors && authErrors.password_confirmation)}
        ]"
      >
        <input v-model="form.password_confirmation" :type="isHidePassword ? 'password' : 'text'">
        <span class="form-input__label">
          {{$t('registration.repeat_password')}}
        </span>
        <div v-if="errors.password_confirmation" class="form-input__error" v-text="errors.password_confirmation" />
        <div v-if="authErrors && authErrors.password_confirmation" class="form-input__error">
          <p v-for="(err, index) in authErrors.password_confirmation" :key="index">
            {{ err }}
          </p>
        </div>
      </div>
   
      <div>
        <div class="registration-page__types">
          <label class="form-control">
            <input type="radio" :value="0" v-model="form.type">
            {{$t('registration.individual')}}
          </label>
          <label class="form-control">
            <input type="radio" :value="1" v-model="form.type">
            {{$t('registration.legal')}}
          </label>
        </div>
        <div v-if="errors.type" class="form-input__error" v-text="errors.type" />
      </div>
      <div class="registration-page__agree">
          <label class="input__item-checkbox">
            <input
                type="checkbox"
                value="'all'"
                required
                v-model="form.isAgreePolicy"
            >
            <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
            <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{$t('registration.agree_with')}}
                <router-link to="/privacy-policy">{{$t('registration.privacy_policy')}}</router-link>
                <!-- {{$t('registration.and')}} -->
                <!-- <router-link to="/agreement">{{$t('registration.user_agreement')}}</router-link> -->
              </span>
            </span>
          </label>
          <label class="input__item-checkbox">
            <input
                type="checkbox"
                value="'all'"
                required
                v-model="form.isAgreeAgreement"
            >
            <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
            <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{$t('registration.agree_with')}}
                <!-- <router-link to="/privacy-policy">{{$t('registration.privacy_policy')}}</router-link>
                {{$t('registration.and')}} -->
                <router-link to="/agreement">{{$t('registration.user_agreement')}}</router-link>
              </span>
            </span>
          </label>
          <label class="input__item-checkbox">
            <input
                type="checkbox"
                value="'all'"
                required
                v-model="form.isSpecialTerms"
            >
            <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
            <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{$t('registration.agree_with')}}
                <!-- <router-link to="/privacy-policy">{{$t('registration.privacy_policy')}}</router-link>
                {{$t('registration.and')}} -->
                <router-link to="/special-terms">{{$t('registration.special_terms')}}</router-link>
              </span>
            </span>
          </label>
        <div v-if="errors.isAgree" class="form-input__error" v-text="errors.isAgree" />
      </div>
      <ButtonElement
        :text="$t('login.register')"
        :disabled="locale === 'RU' && !phoneVerified"
        @click-button="registerUSA"
      />
      <div class="registration-page__link-auth">
        {{$t('registration.have_account')}}
        <router-link :to="{name: 'Login'}">{{$t('registration.log_in')}}</router-link>
      </div>
    </FormElement>
    <div v-if="isRegister" class="registration-page__notice">
      <div class="registration-page__notice-title">
        {{$t('registration.notice_title')}}
      </div>
      <div class="registration-page__notice-text">
        {{$t('registration.verification')}} <span v-if="isNotice">{{$t('registration.repeat')}}</span>{{$t('registration.verification_text')}} 
      </div>
      <router-link
        class="registration-page__notice-link"
        :class="{'locale-usa': locale==='USA'}"
        :to="{name: 'Login'}"
      >
        {{$t('registration.log_in')}}
      </router-link>
      <div class="registration-page__notice-repeat" @click="repeatVerify">
        {{$t('registration.send_again')}}
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import FormElement from "../components/elements/FormElement";
import ButtonElement from "../components/elements/ButtonElement";
import formValidate from "../mixin/formValidate";

export default {
  name: "Registration",
  components: {
    FormElement,
    ButtonElement
  },
  mixins: [formValidate],
  data () {
    return {
      loading: false,
      locale: process.env.VUE_APP_LOCALE,
      form: {
        email: null,
        phone: null,
        password: null,
        password_confirmation: null,
        type: null,
        isAgree: false,
        isAgreePolicy: false,
        isAgreeAgreement: false,
        inn:null,
        partner: '',
        isSpecialTerms: false,
        isAgreeAdds: false,
        utms:''
      },
      errors: {
        email: null,
        phone: null,
        password: null,
        password_confirmation: null,
        type: null,
        isAgree: null,
        inn:null,
        phoneSmsCode: null,
      },
      maskFormPhone: '+7',
      phoneSmsCode: '',
      phoneSend: false,
      phoneVerified: false,
      smsTimer: null,
      smsTimerCount: 0,

      isRegister: false,
      checkingPassword: false,
      isContainNumber: false,
      isContainLetter: false,
      isContainBigLetter: false,
      isNotice: false,
      showSendVerify: false,
      vueTelInput: '',
      countryValue: 'RU',
      flagImagePath: require('@/assets/images/flag-russia.png'),
      countryOptions: [
        {
          value: 'BY',
          label: 'BY',
          flag: require('@/assets/images/flag-belarus.png')
        }, 
        {
          value: 'RU',
          label: 'RU',
          flag: require('@/assets/images/flag-russia.png')
        }, 
        {
          value: 'KZ',
          label: 'KZ',
          flag: require('@/assets/images/flag-kazakhstan.png')
        }
      ],
      // phoneMasksList: [
      //   {code:'RU', mask: '+7(###)###-##-##'},
      //   {code:'BY', mask: '+375(##)###-##-##'},
      //   {code:'KZ', mask: '+7(###)###-##-##'}
      // ],
      phoneMasksList: [
        {code:'RU', mask: '+7 (###) ###-##-##'},
        {code:'BY', mask: '+375 (##) ###-##-##'},
        {code:'KZ', mask: '+7 (###) ###-##-##'}
      ],
      placeholderList: [
        {code:'RU', mask: '+7 (###) ###-##-##'},
        {code:'BY', mask: '+375 (##) ###-##-##'},
        {code:'KZ', mask: '+7 (###) ###-##-##'}
      ],
      optionalParam:null
    }
  },
  computed: {
    currentPassword() {
      return this.form.password
    },
    getPhone: {
      get() {
        return this.maskFormPhone
      },
      set(value) {
        this.form.phone = value.replace(/^\+\d{1,3}\s/, '').replace(/[()\s-]/g, '');
        this.maskFormPhone = value
      }
    },
    // unmaskedPhone() {
    //   const re = new RegExp('-', 'g');
    //   return this.form.phone.replace(re, '').replace('(', '').replace(')', '')
    // },
    unmaskedPhone() {
      const re = new RegExp('-', 'g');
      let modifiedPhone = this.form.phone.replace(re, '').replace('(', '').replace(')', '');
      if (this.countryValue === 'RU' || this.countryValue === 'KZ') {
        modifiedPhone = '+7' + modifiedPhone;
      } else if (this.countryValue === 'BY') {
        modifiedPhone = '+375' + modifiedPhone;
      }

      return modifiedPhone;
    },
    phoneMask() {
      let maskValueString
      const codeObj =  this.phoneMasksList.find(value =>  value.code === this.countryValue )
      maskValueString = codeObj ? codeObj.mask : '';
      return maskValueString
    }
  },
  watch: {
    currentPassword(val) {
      const regNumber = /^(?=.*\d).+$/
      this.isContainNumber = regNumber.test(String(val))
      const regLetter = /^(?=.*[a-z]).+$/
      this.isContainLetter = regLetter.test(String(val))
      const regBigLetter = /^(?=.*[A-Z]).+$/
      this.isContainBigLetter = regBigLetter.test(String(val))
    },
    phoneSmsCode() {
      this.errors.phoneSmsCode = null
    }
  },
  methods: {
    showVerify() {
      this.showSendVerify = !this.showSendVerify
    },
    // registerUSA () {
    //   this.validateForm()
    //   if (this.isError) {
    //     return false
    //   }
    //   const data = this.form
    //   data.privacy_policy_accepted = true
    //   data.user_agreement_accepted = true
    //   if (this.locale !== 'USA') {
    //     data.phone = this.unmaskedPhone
    //   }
    //   this.loading = true
    //   this.$store.dispatch('Auth/register', data)
    //   .then(() => {
    //           this.isRegister = true
    //           this.$metrika.reachGoal('form_submit')
    //           var _tmr = window._tmr || (window._tmr = []);
    //           _tmr.push({ type: 'reachGoal', id: 3305885, goal: 'vkpixel_registration'});
    //         })
    //   .catch(err => console.log(err))
    //   .finally(() => {
    //     this.loading = false
    //   })
    
    // },
    registerUSA () {
      this.validateForm()
      if (this.isError) {
        return false
      }
      this.$recaptchaLoaded().then(() => {
        this.$recaptcha('register').then((token) => {
          const data = this.form
          data.privacy_policy_accepted = true
          data.user_agreement_accepted = true
          data.recaptcha_token = token
          data.phone = this.phoneFormat(this.form.phone)
          
          if (this.locale !== 'USA') {
            data.phone = this.unmaskedPhone
          }
          this.loading = true
          this.$store.dispatch('Auth/register', data)
            .then(() => {
              this.isRegister = true
              this.$metrika.reachGoal('form_submit')
              var _tmr = window._tmr || (window._tmr = []);
              _tmr.push({ type: 'reachGoal', id: 3305885, goal: 'vkpixel_registration'});
            })
            .catch(err => console.log(err))
            })
            .finally(() => {
              this.loading = false
            })
          })
    },
    register () {
      this.validateForm()
      if (this.isError) {
        return false
      }
      this.$recaptchaLoaded().then(() => {
        this.$recaptcha('register').then((token) => {
          const data = {...this.form}
          data.privacy_policy_accepted = true
          data.user_agreement_accepted = true
          data.recaptcha_token = token
          data.referral_code = this.optionalParam
          if (this.locale !== 'USA') {
            data.phone = this.unmaskedPhone
            // data.phone = this.unmaskedPhone(this.form.phone)
            // data.phone = this.phoneFormat(this.form.phone)
          
          }          
          data.phone = this.phoneFormat(data.phone)
          

          this.loading = true
          this.$store.dispatch('Auth/register', data)
          .then(() => {
            this.isRegister = true
            this.$metrika.reachGoal('form_submit')
            var _tmr = window._tmr || (window._tmr = []);
            _tmr.push({ type: 'reachGoal', id: 3305885, goal: 'vkpixel_registration'});
            if(this.isKazakhstanPhoneNumber(data.phone)) {
              this.showSendVerify = true
              this.phoneVerified = true
              throw new Error('Skipping to catch and finally');
            }
          })
          .then(()=> {
            this.sendPhone()
          })
          .catch(err => console.log(err))
          })
          .finally(() => {
            this.loading = false
          })
        })
    },
    validateForm () {
      if (this.form.type === null) {
        this.errors.type = this.$t('registration.job_type')
      }
      if (this.locale === 'RU' && (!this.form.isAgreePolicy || !this.form.isAgreeAdds ) ) {
        this.errors.isAgree = this.$t('registration.should_agree')
      }
      if (!this.form.email) {
        this.errors.email = this.$t('registration.input_email')
      } else {
        this.validateEmail()
      }
      if (!this.form.phone) {
        this.errors.phone = this.$t('registration.input_phone')
      } if(this.locale === 'USA' && (!this.form.isAgreePolicy || !this.form.isAgreeAgreement || !this.form.isSpecialTerms)) {
        this.errors.isAgree = this.$t('registration.should_agree')
      } else {
        
        // const regPhone = /^(\+7\(?\d{3}\)?\d{3}-?\d{2}-?\d{2})$/
        // const regPhone = this.locale==='RU' ? (/^(\+7\(?\d{3}\)?\d{3}-?\d{2}-?\d{2})$/) : (/^(\+)?([0-9]+)$/)
        const phoneIsValid = this.locale==='RU' 
          ? this.countryValue === 'BY'
            ? (/^\+375 \(\d{2}\) \d{3}-\d{2}-\d{2}$/).test(this.maskFormPhone)
            : (/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/).test(this.maskFormPhone)
          : (/^(\+)?([0-9]+)$/).test(String(this.form.phone))

        if (!phoneIsValid) {
          this.errors.phone = this.$t('registration.correct_number')
        }
      }
      if (!this.form.password) {
        this.errors.password = this.$t('registration.input_password')
      }
      if (!this.form.password_confirmation) {
        this.errors.password_confirmation = this.$t('registration.password_repeat')
      } else if (this.form.password_confirmation !== this.form.password) {
        this.errors.password_confirmation = this.$t('registration.passwords_dont_match')
      }
    },
    repeatVerify () {
      this.$store.dispatch('Auth/repeatVerifyFromRegistration', { email: this.form.email })
        .then(() => this.isNotice = true)
        .catch(err => {
          console.log(err)
        })
    },
    sendPhone(type) {
      if (!this.form.phone) {
        this.errors.phone = this.$t('registration.input_phone')
        return false
      } else {
        const regPhone = this.locale==='RU' ? /^[0-9-]+$/ : (/^(\+)?([0-9]+)$/)
        if (!regPhone.test(String(this.form.phone))) {
          this.errors.phone = this.$t('registration.correct_number')
          return false
        }
      }
      this.loading = true
      this.$store.dispatch(type === 'resend' ? 'Auth/resendSmsCode' : 'Auth/sendSmsCode', { phone: this.unmaskedPhone })
      // this.$store.dispatch(type === 'resend' ? 'Auth/resendSmsCode' : 'Auth/sendSmsCode', { phone: this.unmaskedPhone(this.form.phone) })
        .then((data) => {
          if (data.data && data.data.success) {
            this.phoneSend = true
            this.smsTimerCount = 60
            this.smsTimer = setInterval(() => {
              this.smsTimerCount--
              if (!this.smsTimerCount) {
                clearInterval(this.smsTimer)
                this.smsTimer = null
              }
            }, 1000);
          } else {
            console.log(data.data.errors)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    checkSmsCode() {
      if (!this.phoneSmsCode) {
        this.errors.phoneSmsCode = this.$t('registration.no_sms_code')
        return false
      }
      this.loading = true
      this.$store.dispatch('Auth/checkSmsCode', { phone: this.unmaskedPhone, code: this.phoneSmsCode })
      // this.$store.dispatch('Auth/checkSmsCode', { phone: this.unmaskedPhone(this.form.phone), code: this.phoneSmsCode })
        .then((data) => {
          if (data.data && data.data.success) {
            this.phoneVerified = true
          } else {
            this.$notify({
              message: this.$t('registration.falseCode'),
              type: 'error'
            });
          }       
        })
        .catch(() => {
          this.$notify({
            message: this.$t('registration.falseCode'),
            type: 'error'
          });
        })
        .finally(() => {
          this.loading = false
        })
    },
    phoneFormat(val) {
      const regex = /[()\-\s]/g;
      if (val) {
        const result = val.replace(regex, '');
        return result;
      }
      return ''
    },
    isKazakhstanPhoneNumber(phoneNumber) {
      if (typeof phoneNumber !== 'string') {
        phoneNumber = String(phoneNumber);
      }
      phoneNumber = phoneNumber.replace(/\s|\(|\)|-/g, '');
      if (phoneNumber.startsWith('+7')) {
        let networkCode = phoneNumber.slice(2, 5);
        if (networkCode.startsWith('6') || networkCode.startsWith('7')) {
          return true;
        }
      }
      return false;
    },
    selectCountry() {
      const selectedCountry = this.countryOptions.find(item => item.value === this.countryValue);
      
      this.getPhone = selectedCountry.label === 'RU' || selectedCountry.label === 'KZ'
        ? '+7 '
        : '375 '

      if (selectedCountry) {
        this.flagImagePath = selectedCountry.flag;
      }

      this.$nextTick(() => {
        this.handlePhoneFocus();
      });

    },
    handlePhoneFocus() {
      this.$refs.phone.focus()
    },
    setPlaceholderValue(placeholder) {
      const placeholderObject = this.placeholderList.find(item => item.code === placeholder);
      return placeholderObject.placeholder
    },
    getRemainUTM() {
      const queryParams = this.$route.query;
      const remainingParams = Object.entries(queryParams)
      .filter(([key]) => key !== 'utm_partner')
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
      this.form.utms = remainingParams;
    }
  },
  mounted() {
    this.optionalParam = this.$route.params.optionalParam;
    if(this.$route.query && this.$route.query.utm_partner) this.form.partner = this.$route.query.utm_partner
    if (this.$route.query && this.$route.query.email) {
      this.form.email = this.$route.query.email
    }
    // console.log('kazah', this.isKazakhstanPhoneNumber('+77077037325'))
    this.getRemainUTM();
  },
  
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

  .registration-page {
    .form-input-width-country-select {
      .country-select-wrapper {
        font-family: 'Roboto, sans-serif';
        max-width: 11rem;
        .el-select {
          input[type='text'] {
            height: 5rem;
            border: 1px solid #BFC2C6;
            border-radius: 2px;
            padding-left: 10px;
           font-size: 14px;
            color: rgb(135, 139, 147) 
          }
          .el-input {
            &__prefix:nth-child(2) {
              display: flex;
              align-items: center;
              left: 50%;
              transform: translateX(-40%);
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
        .country-select {
          &__flag {
            width: 20px;
            height: 20px;
          }
        }
      }
      .el-select-dropdown.country-select {
        height: 6rem;
      }
      .form-input {
        margin-top: 0;
        margin-bottom: 0;
      }
        display: flex;
        gap: 1rem;
        align-items: center;
    }
    
    &__agree {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;

      .input__item-checkbox {
        align-items: flex-start;

        &__title {
          font-size: 1.6rem;
          line-height: 1.9rem;
          color: $text;

          a {
            color: var(--primary);

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    &__link-auth {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $grey1;
      text-align: center;

      a {
        color: var(--primary);

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__notice {
      background: #FFFFFF;
      box-shadow: 0 5px 10px rgba(158, 158, 158, 0.1);
      border-radius: 5px;
      width: 43rem;
      padding: 4rem;

      &-title {
        font-weight: bold;
        font-size: 2rem;
        line-height: 2.3rem;
        color: $basicBlack;
        margin-bottom: 0.8rem;
      }

      &-text {
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: $basicBlack;
        margin-bottom: 4rem;
      }

      &-link {
        border-radius: 4px;
        background: var(--primary);
        color: $basicWhite;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 1.9rem;
        transition: 0.3s ease-out;
        &.locale-usa:hover {
          background:var(--primary)
        }
        &:hover {
          background: $primaryHover;
        }
      }

      &-repeat {
        font-size: 1.6rem;
        line-height: 1.9rem;
        text-align: center;
        text-decoration: underline;
        color: var(--primary);
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__types {
      padding-top: 2rem;
      
      label {

        display: flex;
        margin-bottom: .5rem;
        cursor: pointer;

        input {
          margin-right: 1rem;
        }
      }
    }

    @include below(577px) {
      .form-input-width-country-select {
        margin-bottom: 16px;
      }

      &__agree {
        margin-top: 0;
        margin-bottom: 16px;

        .input__item-checkbox__title {
          font-size: 14px;
          line-height: 1.4;
        }
      }

      &__link-auth {
        font-size: 14px;
        line-height: 1.4;
      }
    }

    &__phone-row {
      margin-top: 2rem;

      .button {
        margin: 0;
        background: none;
        color: var(--primary);

        &:hover {
          color: #FFFFFF;
        }
      }
    }

    &__phone-row-inner {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      // grid-template-columns: 1fr;
      gap: 15px;
    }

    &__phone-verified-icon {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0,-35%);
    }

    &__phone-row-message {
      font-size: 1.2rem;
      font-weight: 400;
      color: #9BA2AB;
      padding-top: 6px;

      span {
        color: #9CAFF1;
      }

      &.is-button {
        color: var(--primary);
        cursor: pointer;
        width: max-content;
      }
    }
  }
</style>
