<template>
    <div class="transfer">
        <div class="container">
            <div class="transfer__title">
                Перенос РА с одного БА на другой
            </div>
            <div class="transfer-wrap">
                <div class="transfer__row">
                    <div class="transfer__row-item">
                        <div class="dropdown-wrap">
                            <div class="dropdown-title">Исходный БА</div>
                            <SingleSelector :options="clients" @handleChange="setEmitter" />
                        </div>
                    </div>
                    <div class="transfer__row-item">
                        <div class="dropdown-wrap">
                            <div class="dropdown-title">Рекламные площадки</div>
                            <SingleSelector :options="platformsList" @handleChange="setPlatform" />
                        </div>
                    </div>
                </div>
                <div class="transfer__row">
                    <div class="transfer__row-item">
                        <div class="dropdown-wrap">
                            <div class="dropdown-title">Целевой БА</div>
                            <SingleSelector :options="clients" @handleChange="setConsumer" />
                        </div>
                    </div>
                    <div class="transfer__row-item">
                        <div class="dropdown-wrap">
                            <div class="dropdown-title">Рекламный аккаунт</div>
                            <SingleSelector :options="addAccountsList" @handleChange="setAddAccountID" />
                        </div>
                    </div>
                </div>
                <div class="transfer__row">
                    <div class="transfer__row-item">
                        <div class="transfer-radio-wrap">
                            <label class="settings-choice-item">
                                <input type="radio" v-model="transactions" value="no">
                                <span>
                                    Не переносить транзакции откруток
                                </span>
                            </label>
                            <label class="settings-choice-item">
                                <input type="radio" v-model="transactions" value="all">
                                <span>
                                    Перенос всех транзакций откруток
                                </span>
                            </label>
                            <label class="settings-choice-item">
                                <input type="radio" v-model="transactions" value="date">
                                <span>
                                    Перенос транзакций откруток с даты
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="transfer__row-item">
                        <div class="dropdown-wrap calendar">
                            <div class="dropdown-title">Дата переноса откруток</div>
                            <SingleDatePicker :title="$t('data_picker.title')" :use-timezone="true"
                                @changeDates="changeDate" />
                        </div>
                    </div>
                </div>
                <div v-if="error" class="transfer__row">
                    <span class="transfer__error">Выбраны не все поля</span>
                </div>
                <div class="transfer__row">
                    <div class="transfer__row-item">
                        <ButtonElement :text="'Перенести'" @click-button="sendData" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SingleSelector from '@/components/sort/SingleSelector';
import SingleDatePicker from "@/components/sort/SingleDatePicker";
import ButtonElement from "@/components/elements/ButtonElement";

import moment from 'moment';
import axios from 'axios';

const apiUrl = process.env.VUE_APP_API_URL
const token = localStorage.getItem('token')

export default {
    name: 'TransferAccounts',
    components: {
        SingleSelector,
        SingleDatePicker,
        ButtonElement
    },
    data() {
        return {
            locale: process.env.VUE_APP_LOCALE,
            selectedDate: "",
            transactions: 'no',
            rawClients: [],
            platforms: [],
            consumer: null,
            emitter: null,
            adPlatform: null,
            addAccounts: [],
            addAccountID: null,
            errors: {
                emitter: null,
                consumer: null,
                adPlatform: null,
                addAccountID: null
            },
            error: false
        }
    },
    computed: {
        startDate() {
            const date = moment(this.selectedDate.startDate).format('YYYY-MM-DD')
            return date
        },
        endDate() {
            const date = moment(this.selectedDate.endDate).format('YYYY-MM-DD')
            return date
        },
        clients() {
            return this.rawClients.reduce((acc, item) => {
                acc.push({
                    id: item.id,
                    email: item.email,
                    name: item.name
                })
                return acc
            }, [])
        },
        platformsList() {
            return this.platforms.reduce((acc, item) => {
                acc.push({
                    id: item.slug,
                    name: item.name
                })
                return acc
            }, [])
        },
        addAccountsList() {
            return this.addAccounts.reduce((acc, item) => {
                acc.push({
                    id: item.id,
                    name: item.account_name
                })
                return acc
            }, [])
        },
        currentDate() {
            const date = moment(new Date()).format('YYYY-MM-DD')
            return date
        }
    },
    watch: {
        adPlatform: {
            deep: true,
            handler(newVal, oldVal) {
                this.error = false;
                if (newVal && oldVal && (newVal !== oldVal) && this.emitter[0]?.id) {
                    this.getAddAccounts(newVal[0].id, this.emitter[0].id);
                }
            }
        },
        emitter: {
            deep: true,
            handler(newVal, oldVal) {
                this.error = false;
                if (newVal && oldVal && (newVal !== oldVal) && this.adPlatform[0]?.id && newVal[0]?.id) {
                    this.getAddAccounts(this.adPlatform[0].id, newVal[0].id);
                }
            }
        },
        consumer: {
            deep: true,
            handler() {
                this.error = false
            }
        },
        addAccountID: {
            deep: true,
            handler() {
                this.error = false
            }
        }
    },
    mounted() {
        this.getData();
        this.getPlatforms();
    },
    methods: {
        changeDate(val) {
            if (val) {
                this.selectedDate = val
            }
        },
        validate() {
            if ((!this.adPlatform || Object.keys(this.adPlatform).length === 0) ||
                (!this.emitter || Object.keys(this.emitter).length === 0) ||
                (!this.consumer || Object.keys(this.consumer).length === 0) ||
                (!this.addAccountID || Object.keys(this.addAccountID).length === 0)) {
                return false
            }
            return true
        },
        sendData() {
            return new Promise((resolve, reject) => {
                if (!this.validate()) {
                    this.error = true
                } else {
                    axios({
                        url: `${apiUrl}/advertservice/transfer`,
                        method: 'POST',
                        data: {
                            account_id: this.addAccountID[0]?.id,
                            ba_id: this.consumer[0]?.id,
                            transfer_type: this.transactions,
                            date: this.startDate ? this.startDate : this.currentDate,
                        },
                        headers: { 'Authorization': `Bearer ${token}` }
                    })
                        .then(resp => {
                            if (resp.data.success) {
                                // this.emitter = null
                                // this.consumer = null
                                // this.adPlatform = null
                                // this.addAccountID = null
                                // this.addAccounts = []
                                this.$notify({
                                    message: "Рекламный аккаунт успешно перенесен",
                                    type: 'success'
                                });
                                window.location.reload();
                            } else {
                                this.$notify({
                                    message: "Произошла ошибка, попробуйте ещё раз",
                                    type: 'error'
                                });
                            }
                        })
                        .catch(err => {
                            console.log(err.response)
                            reject(err)
                        })
                }

            })
        },
        getData() {
            this.loading = true
            const token = localStorage.getItem('token')
            return new Promise((resolve, reject) => {
                axios({
                    url: `${apiUrl}/admin/clients`,
                    method: 'GET',
                    params: { per_page: 1000, is_short: 1 },
                    headers: { 'Authorization': `Bearer ${token}` }
                })
                    .then(resp => {
                        this.loading = false
                        this.rawClients = resp.data.data
                    })
                    .catch(err => {
                        console.log(err.response)
                        reject(err)
                    })
                    .finally(() => this.loading = false)
            })
        },
        getPlatforms() {
            return new Promise((resolve, reject) => {
                axios({
                    url: apiUrl + '/advertservice/list',
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${token}` }
                })
                    .then(resp => {
                        this.platforms = resp.data.data
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err.response)
                        reject(err)
                    })
            })
        },
        setEmitter(value) {
            this.emitter = value
        },
        setConsumer(value) {
            this.consumer = value
        },
        setPlatform(value) {
            this.adPlatform = value
        },
        getAddAccounts(platform, id) {
            return new Promise((resolve, reject) => {
                axios({
                    url: apiUrl + '/advertservice/get-accounts',
                    method: 'GET',
                    params: { service: platform, business_account_id: id },
                    headers: { 'Authorization': `Bearer ${token}` }
                })
                    .then(resp => {
                        this.addAccounts = resp.data.data.data
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err.response)
                        reject(err)
                    })
            })
        },
        setAddAccountID(value) {
            this.addAccountID = value
        }
    }
}
</script>
<style lang="scss">
.transfer-wrap {
    margin-top: 5rem;
    display: flex;
    row-gap: 4rem;
    flex-direction: column;
}

.transfer {
    padding: 5rem 0;

    &__title {
        font-weight: 700;
        font-size: 3rem;
        line-height: 3.6rem;
        margin-bottom: 3rem;
    }

    &__row {
        display: flex;
        gap: 3.5rem;
    }

    &__row-item {
        width: calc(50% - 3.5rem);
    }

    &__error {
        color: red;
    }
}

.dropdown-wrap {
    .selector__content {
        width: 100%;
    }

    .selector__options {
        max-height: 40rem;
        overflow-y: auto;
    }

    .selector__content {
        position: relative;

        .selector__arrow {
            img {
                position: absolute;
                right: 1.5rem;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

.dropdown-title {
    font-family: 'Roboto';
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.transfer-radio-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 1.9rem;
}

.settings-choice-item {
    span {
        margin-left: 1rem;
    }
}

.transfer__row-item {
    .button {
        min-width: 25rem;
        min-height: 5rem;
    }
}

.form-input.selector__options-search {
    margin-top: 1rem;
}

@media (max-width: 768px) {
    .transfer__row {
        flex-direction: column;
    }

    .transfer__row-item {
        width: 100%;
    }

    .dropdown-wrap.calendar {
        .date-picker {
            width: 100%;
            margin: 0 auto;
        }
    }
}
</style>