<template>
    <div class="icon" :class="'icon--' + type">
      <img :src="require('@/assets/icons/icon-' + iconFormatted + '.svg')" alt="icon">
    </div>
  </template>
  
  <script>
  export default {
    name: "IconReferral",
    props: {
      icon: {
        type: Number,
        default: 0
      }
    },
    computed: {
      type () {
        let type = ''
        switch (this.icon) {
          case 1:
            type = 'green'
            break;
          case 2:
            type = 'purple'
            break;
          case 3:
            type = 'orange'
            break;
        }
        return type
      },
      iconFormatted() {
        let prefix = ''
        switch (this.icon) {
          case 1:
            prefix = 'replenishment';
            break;
          case 2:
            prefix = 'withdrawal'
            break;
          case 3:
            prefix = 'adjustment'
            break;
        }
        return prefix
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
    @import "../../assets/styles/helpers/variables";
    @import "../../assets/styles/helpers/mixin";
  
    .icon {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
  
      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
  
      &--green {
        background: #27AE60;
      }
      
      &--purple {
        background: #9CAFF1;
      }

      &--orange {
        background: #FF974C;
      }
    }
  </style>
  