<template>
  <div class="agreements-block">
    <div
      v-if="user && !user.user_agreement_accepted"
      class="agreements-block__inner"
    >
      <p class="agreements-block__text">
        Появились изменения в
        <router-link
          class="agreements-block__link"
          :to="{name: 'Agreement'}"
        >
          пользовательском соглашении
        </router-link>
      </p>

      <ButtonElement
        text="Принять"
        class="agreements-block__btn"
        @click-button="acceptNewUserTerms('user_agreement_accepted')"
      />
    </div>
    <div
      v-if="user && !user.privacy_policy_accepted"
      class="agreements-block__inner"
    >
      <p class="agreements-block__text">
        Появились изменения в
        <router-link
          class="agreements-block__link"
          :to="{name: 'PrivacyPolicy'}"
        >
          политике конфиденциальности
        </router-link>
      </p>

      <ButtonElement
        text="Принять"
        class="agreements-block__btn"
        @click-button="acceptNewUserTerms('privacy_policy_accepted')"
      />
    </div>
    <div
      v-if="activeAccount && !activeAccount.offer_agreement_accepted"
      class="agreements-block__inner"
    >
      <p class="agreements-block__text">
        Появились изменения по
        <router-link
          class="agreements-block__link"
          :to="{name: 'DogovorOferty'}"
        >
          договору оферты
        </router-link>
      </p>

      <ButtonElement
        text="Принять"
        class="agreements-block__btn"
        @click-button="acceptNewOfferTerms"
      />
    </div>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/ButtonElement";
import axios from "axios";

export default {
  name: 'AgreementsBlock',
  components: {
    ButtonElement,
  },
  computed: {
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    user() {
      return this.$store.state.Auth.user
    },
  },
  methods: {
    acceptNewOfferTerms() {
      const data = {
        offer_agreement_accepted: true
      }
      axios({
        url: process.env.VUE_APP_API_URL + `/business-account/${this.activeAccount.id}/accept-offer-agreement`,
        method: 'POST',
        data,
      })
        .then(({ data }) => {
          this.$store.commit('Auth/setData', { label: 'activeAccount', data: data.data })
        })
    },
    acceptNewUserTerms(term) {
      const data = {}
      data[term] = true
      axios({
        url: process.env.VUE_APP_API_URL + `/users/${this.user.id}/accept-conditions`,
        method: 'POST',
        data,
      })
        .then(({ data }) => {
          this.$store.commit('Auth/setData', { label: 'user', data: data.data })
        })
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";

.agreements-block {
  width: 100%;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $basicWhite;
    box-shadow: 0 4px 12px rgba(192, 192, 192, 0.12);
    padding: 3rem;
    margin-bottom: 2rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__text {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $basicBlack;
    padding: 0;
    margin: 0 3rem 0 0;
  }

  &__link {
    display: inline-block;
    position: relative;
    color: $primary;

    &::before {
      width: 100%;
      height: 1px;
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: $primary;
      transition: opacity .4s linear;
    }

    &:hover {

      &::before {
        opacity: 0;
      }
    }
  }

  &__btn {
    height: auto;
    padding: 1rem 5rem;
  }
}
</style>
