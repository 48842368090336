import axios from 'axios'

const apiUrl = process.env.VUE_APP_API_URL

const state = () => ({
  platformsList: [],
  accountsList: null,
  statistic: {},
  servicesBudget: null,
  history: null,
  sum: null,
  paginationEvents: null,
  pagination: {
    total: 0,
    currentPage: 1
  },
  transactionTypes: null,
  additionalManagerName: null,
  additionalManagerStatus: null,
  additionalManagerError: null,
  additionalManagerId: null,
  transferManager: null,
  transferAccountName: null,
  accountsByBusinessAccount: null,
  vkObservers: null,
  vkObserverEdit: null,
  platformBalance: null,

  observersAccounts: null,
  currentNewUser: null,
  accountAddedSuccess: false
})

const getters = {}
const mutations = {
  logout (state) {
    state.platformsList = null
    state.accountsList = null
    state.statistic = null
  },
  setData (state, data) {
    state[data.label] = data.data
  },
  setAccountsList (state, data) {
    if (state.accountsList && state.accountsList.length) {
      if (data.data && data.data.length) {
        if ((state.accountsList[0].service !== data.data[0].service)) {
          state.accountsList = data.data
        } else {
          const check = []
          data.data.forEach((el) => {
            check.push(!!state.accountsList.find(el2 => el2.id === el.id))
          })
          if (check.includes(true)) {
            state.accountsList = data.data
          } else {
            state.accountsList = state.accountsList.concat(data.data)
          }
        }
      } else {
        state.accountsList = data.data
      }
    } else {
      state.accountsList = data.data
    }
  },
  setBudget (state, data) {
    state.servicesBudget = data.data.services
  }
}
const actions = {
  logout({ commit }) {
    return new Promise((resolve) => {
      commit('logout')
      localStorage.removeItem('isOpenReferral')
      resolve()
    })
  },
  getPlatforms ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/advertservice/list', params, method: 'GET' })
        .then(resp => {
          commit('setData', { label: 'platformsList', data: resp.data.data })
          // commit('setData', { label: 'platformsList', data: mockResponseTelegram })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getPlatformBalance ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/advertservice/accounts/total/balance`, params, method: 'GET' })
        .then(resp => {
          commit('setData', { label: 'platformBalance', data: resp.data.data.total_balance })
          resolve(resp.data.data)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getAccounts ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/advertservice/get-accounts`, params, method: 'GET' })
        .then(resp => {
          commit('setAccountsList', { label: 'accountsList', data: resp.data.data.data })
          commit('setData', {
            label: 'pagination',
            data: { total : resp.data.data.total, currentPage: resp.data.data.current_page, lastPage: resp.data.data.last_page }})
          resolve(resp.data.data)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getAddAccountsForRepresentative({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/business-partners/accounts/${data.user_id}/${data.ba_id}/${data.service}` ,
        method: 'GET',
        // headers: { 'Authorization': `Bearer ${data.token}` }
      })
        .then(resp => {
          commit('setAccountsList', { label: 'accountsList', data: resp.data.data.accounts.data})
          commit('setData', {
            label: 'pagination',
            data: { total : resp.data.data.total, currentPage: resp.data.data.current_page, lastPage: resp.data.data.last_page }})
          resolve(resp.data.data)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  addAccount (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/advertservice/add-account`, data, method: 'POST', headers: { 'Authorization': `Bearer ${data.token}` } })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  addRequest (_, data) {
    return new Promise((resolve, reject) => {
      const {account_url, business_account_id, account_name, email_google } = data
      const body = new FormData();
      body.append('business_account_id', business_account_id);
      body.append('account_name', account_name);
      body.append('email_google', email_google);
      axios({ url: `${apiUrl}/advertservice/${account_url}`, data: body, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  managerAddAccount (ctx, {data, token}) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/advertservice/add-account`, data, method: 'POST', headers: { 'Authorization': `Bearer ${token}` } })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getAdClients ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/advertservice/clients`, params, method: 'GET' })
        .then(resp => {
          commit('setData', { label: 'observersAccounts', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getLastMarkData ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/advertservice/last-mark-data`, params, method: 'GET' })
        .then(resp => {
          commit('setData', { label: 'lastMarkData', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  changeAccountName (ctx, {id, data}) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/advertservice/${id}/name`, data, method: 'PUT' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  deleteAccount (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/admin/client/delete`,
        method: 'POST',
        data: data.data,
        headers: { 'Authorization': `Bearer ${data.token}` }
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  addAdditionalManager ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/business-account/${data.id}/additional-manager`, data: data.data, method: 'POST' })
        .then(resp => {
          commit('setData', { label: 'additionalManagerName', data: resp.data.data.manager_name })
          commit('setData', { label: 'additionalManagerStatus', data: resp.data.data.status })
          commit('setData', { label: 'additionalManagerError', data: resp.data.data.decline_message })
          commit('setData', { label: 'additionalManagerId', data: resp.data.data.id })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getAdditionalManager ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/business-account/${data.id}/additional-manager`, method: 'GET', params: data.params })
        .then(resp => {
          if (resp.data.data && resp.data.data.length) {
            let managerData = resp.data.data.find(el => el.status !== 'deleted');
            if (managerData) {
              commit('setData', { label: 'additionalManagerName', data: managerData.manager_name })
              commit('setData', { label: 'additionalManagerStatus', data: managerData.status })
              commit('setData', { label: 'additionalManagerError', data: managerData.decline_message })
              commit('setData', { label: 'additionalManagerId', data: managerData.id })
            }
          } else {
            commit('setData', { label: 'additionalManagerName', data: null })
            commit('setData', { label: 'additionalManagerStatus', data: null })
            commit('setData', { label: 'additionalManagerError', data: null })
            commit('setData', { label: 'additionalManagerId', data: null })
          }
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  deleteAdditionalManager ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/business-account/${data.id}/additional-manager/${data.idManager}`, method: 'DELETE', params: data.params })
        .then(resp => {
          commit('setData', { label: 'additionalManagerName', data: null })
          commit('setData', { label: 'additionalManagerStatus', data: null })
          commit('setData', { label: 'additionalManagerError', data: null })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  checkAccount (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/advertservice/check-account`, data, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getStatistic ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + `/business-account/${data.id}/get-stats-between-dates`, method: 'GET', params: data.params })
        .then(resp => {
          commit('setData', { label: 'statistic', data: resp.data.data })
          commit('setBudget', {data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getHistory ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: apiUrl + `/business-account/${data.id}/transaction`,
        method: 'GET',
        params: data.params,
      })
        .then(resp => {
          commit('setData', { label: 'history', data: resp.data.data })
          const paginationEvents = {
            total: resp.data.meta.total,
            currentPage: resp.data.meta.current_page
          }
          commit('setData', { label: 'paginationEvents', data: paginationEvents })
          commit('setData', { label: 'sum', data: resp.data.sum})
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getTransactioTypes({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: apiUrl + `/transaction-types`,
        method: 'GET',
        params: data.params,
      })
        .then(resp => {
          commit('setData', {label: 'transactionTypes', data: resp.data.data})
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  transferFromAccountToBusinessAccount ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/advertservice/transfer-from-account-to-business-account`, data: data.data, method: 'POST' })
        .then(resp => {
          commit('setData', { label: 'transferManager', data: resp.data.data.service })
          commit('setData', { label: 'transferAccountName', data: resp.data.data.account_name })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getCampaigns(ctx, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: apiUrl + `/advertservice/campaings`,
        method: 'GET',
        params,
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getRepresentativeCampaigns(_, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: apiUrl + `/business-partners/accounts/${params.user_id}/${params.ba_id}/${params.service}/campaigns/${params.account_id}`,
        method: 'GET'
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  addFacebookAccount (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/advertservice/facebook/request`, data, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  addGoogleAccount (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/advertservice/google/request`, data, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },

  addTelegramAccount (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/advertservice/telegram/request`, data, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },

  addTiktokAccount (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/advertservice/tiktok/request`, data, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },

  addPromopagesAccount (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/advertservice/yandexpromo/request`, data, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  addAvitoAccount (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/advertservice/avito/request`, data, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  addVkmarketAccount (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/advertservice/vkmarket/request`, data, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  addMetricaproAccount (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/advertservice/metricapro/request`, data, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },

  addAddsAccount (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/advertservice/${data.url_param}`, data, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },

  checkInn (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/business-account/check-inn', method: 'POST', data })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err.response)
          reject(err.response.data)
        })
    })
  },
  getAccountsByBusinessAccount ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/advertservice/accounts/list', method: 'GET', params: data })
        .then(resp => {
          commit('setData', { label: 'accountsByBusinessAccount', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  addObserver (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/advertservice/observers', method: 'POST', data })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getObserver ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/advertservice/observers', method: 'GET', params: data })
        .then(resp => {
          commit('setData', { label: 'vkObservers', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  observerAttach (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/advertservice/observers/attach', method: 'POST', data })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  observerDetach (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/advertservice/observers/detach', method: 'POST', data })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  dellUser (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/advertservice/observers/delete', method: 'POST', data })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
