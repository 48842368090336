<template>
  <div class="agreements">
    <div class="container">
      <div class="agreements-row">
        <div class="agreements-col">
          <div class="agreements-subtitle">
            Выберите один или несколько документов
          </div>
          <div class="agreements-checkboxes">
            <div class="form-input">
              <label class="input__item-checkbox">
                <input
                  type="checkbox"
                  v-model="form.privacy_policy_accepted"
                >
                <span class="input__item-checkbox__check">
                  <img src="@/assets/icons/icon-done.svg">
                </span>
                <span class="input__item-checkbox__info">
                  <span class="input__item-checkbox__title">
                    Политика конфиденциальности
                  </span>
                </span>
              </label>
            </div>
            <div class="form-input">
              <label class="input__item-checkbox">
                <input
                  type="checkbox"
                  v-model="form.user_agreement_accepted"
                >
                <span class="input__item-checkbox__check">
                  <img src="@/assets/icons/icon-done.svg">
                </span>
                <span class="input__item-checkbox__info">
                  <span class="input__item-checkbox__title">
                    Пользовательское соглашение
                  </span>
                </span>
              </label>
            </div>
            <div class="form-input">
              <label class="input__item-checkbox">
                <input
                  type="checkbox"
                  v-model="form.offer_agreement_accepted"
                >
                <span class="input__item-checkbox__check">
                  <img src="@/assets/icons/icon-done.svg">
                </span>
                <span class="input__item-checkbox__info">
                  <span class="input__item-checkbox__title">
                    Договор оферты
                  </span>
                </span>
              </label>
            </div>
          </div>
          <div class="agreements-subtitle">
            Укажите дату и время когда изменить соглашения
          </div>
          <div class="agreements-picker">
            <el-date-picker
              v-model="date"
              type="date"
              placeholder="Выберите день"
              :clearable="false"
              format="yyyy/MM/dd"
              value-format="yyyy-MM-dd"
            />
          </div>
          <div class="agreements-picker">
            <el-time-select
              v-model="time"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30'
              }"
              placeholder="Выберите время"
              :clearable="false"
              value-format="HH:mm:ss"
            />
          </div>
          <button-element @click-button="send" text="Обновить" :disabled="!date || !time" />
        </div>
        <div v-if="form.offer_agreement_accepted" class="agreements-col">
          <div class="agreements-subtitle">
            Для каких бизнес аккаунтов обновить договор оферты
          </div>
          <div class="agreements-checkboxes">
            <div class="form-input">
              <label class="input__item-checkbox">
                <input
                  type="checkbox"
                  v-model="form.for_physical"
                >
                <span class="input__item-checkbox__check">
                  <img src="@/assets/icons/icon-done.svg">
                </span>
                <span class="input__item-checkbox__info">
                  <span class="input__item-checkbox__title">
                    Для физ. лиц
                  </span>
                </span>
              </label>
            </div>
            <div class="form-input">
              <label class="input__item-checkbox">
                <input
                  type="checkbox"
                  v-model="form.for_legal"
                >
                <span class="input__item-checkbox__check">
                  <img src="@/assets/icons/icon-done.svg">
                </span>
                <span class="input__item-checkbox__info">
                  <span class="input__item-checkbox__title">
                    Для юр. лиц
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from "../components/elements/ButtonElement";
import axios from "axios";

export default {
  name: 'Agreements',
  components: {ButtonElement},
  data() {
    return {
      form: {
        privacy_policy_accepted: false,
        user_agreement_accepted: false,
        offer_agreement_accepted: false,
        for_legal: true,
        for_physical: true,
      },
      date: null,
      time: null,
    }
  },
  methods: {
    send() {
      const data = {}
      if (this.form.privacy_policy_accepted) data.privacy_policy_accepted = true
      if (this.form.user_agreement_accepted) data.user_agreement_accepted = true

      if (this.form.offer_agreement_accepted) {
        data.offer_agreement_accepted = true
        if (this.form.for_legal) data.for_legal = true
        if (this.form.for_physical) data.for_physical = true
      }

      data.changed_at = this.date + ' ' + this.time
      const token = localStorage.getItem('token')
      axios({
        url: process.env.VUE_APP_API_URL + `/admin/reset-accepted-agreements`,
        method: 'POST',
        data,
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(({ data }) => {
          if (data.success) {
            this.$notify({
              message: data.data.message,
              type: 'success'
            });
          } else {
            this.$notify({
              message: 'Ошибка, попробуйте позже',
              type: 'error'
            });
          }
          for (let key in this.form) {
            this.form[key] = false
          }
          this.date = null
          this.time = null
        })
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";

.agreements {
  padding: 5rem 0;

  .button {
    width: 100%;
    height: 5rem;
    margin-top: 3.4rem;
  }

  &-row {
    display: flex;
  }

  &-subtitle {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $basicBlack;
    margin-bottom: 1.6rem;
  }

  &-checkboxes {
    margin-bottom: 4rem;

    .input__item-checkbox__title {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
  }

  &-picker {
    margin-bottom: 1.6rem;

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }

    .el-input__inner {
      height: 5rem;
      line-height: 5rem;
      border-radius: 2px;
      border: 1px solid #D1D4D8;
      padding-left: 1.5rem;
    }

    .el-input__prefix {
      left: auto;
      right: 1.5rem;
    }
  }
}
</style>
