let fixDateFormat = {
  
  data() {
    return {
      months: [
        {
          id: 0,
          shortName: this.$t('monthNames.0')
        },
        {
          id: 1,
          shortName: this.$t('monthNames.1')
        },
        {
          id: 2,
          shortName: this.$t('monthNames.2')
        },
        {
          id: 3,
          shortName: this.$t('monthNames.3')
        },
        {
          id: 4,
          shortName: this.$t('monthNames.4')
        },
        {
          id: 5,
          shortName: this.$t('monthNames.5')
        },
        {
          id: 6,
          shortName: this.$t('monthNames.6')
        },
        {
          id: 7,
          shortName: this.$t('monthNames.7')
        },
        {
          id: 8,
          shortName: this.$t('monthNames.8')
        },
        {
          id: 9,
          shortName: this.$t('monthNames.9')
        },
        {
          id: 10,
          shortName: this.$t('monthNames.10')
        },
        {
          id: 11,
          shortName: this.$t('monthNames.11')
        }
      ],
      monthsRu: [
        {
          id: 0,
          shortName: 'янв'
        },
        {
          id: 1,
          shortName: 'фев'
        },
        {
          id: 2,
          shortName: 'мар'
        },
        {
          id: 3,
          shortName: 'апр'
        },
        {
          id: 4,
          shortName: 'май'
        },
        {
          id: 5,
          shortName: 'июн'
        },
        {
          id: 6,
          shortName: 'июл'
        },
        {
          id: 7,
          shortName: 'авг'
        },
        {
          id: 8,
          shortName: 'сен'
        },
        {
          id: 9,
          shortName: 'окт'
        },
        {
          id: 10,
          shortName: 'ноя'
        },
        {
          id: 11,
          shortName: 'дек'
        }
      ],
      monthsEn: [
        {
          id: 0,
          shortName: 'jan'
        },
        {
          id: 1,
          shortName: 'feb'
        },
        {
          id: 2,
          shortName: 'mar'
        },
        {
          id: 3,
          shortName: 'apr'
        },
        {
          id: 4,
          shortName: 'may'
        },
        {
          id: 5,
          shortName: 'jun'
        },
        {
          id: 6,
          shortName: 'jul'
        },
        {
          id: 7,
          shortName: 'aug'
        },
        {
          id: 8,
          shortName: 'sep'
        },
        {
          id: 9,
          shortName: 'oct'
        },
        {
          id: 10,
          shortName: 'nov'
        },
        {
          id: 11,
          shortName: 'dec'
        }
      ],
    }
  },
  methods: {
    fixDateFormat (fixedDate) {
      if (fixedDate) {
        const date = new Date(fixedDate)
        let str = ''
        const startDay = date.getDate()
        const startMonth = date.getMonth()
        const startYear = date.getFullYear()
        let nameStartMonth =''
        if(this.$i18n._vm.locale==="ru"){
           nameStartMonth = this.monthsRu.find(el => el.id === startMonth).shortName
        }else if(this.$i18n._vm.locale==="en") {
          nameStartMonth = this.monthsEn.find(el => el.id === startMonth).shortName
        }
        // const nameStartMonth = this.months.find(el => el.id === startMonth).shortName
        str = startDay + ' ' + nameStartMonth + ' ' + startYear
        return str
      }
    },
    fixDateFormatTime(fixedDate) {
      if (fixedDate) {
        const date = new Date(fixedDate)
        let str = ''
        const startDay = date.getDate()
        const startMonth = date.getMonth()
        let nameStartMonth =''
        if(this.$i18n._vm.locale==="ru"){
          nameStartMonth = this.monthsRu.find(el => el.id === startMonth).shortName
        }else if(this.$i18n._vm.locale==="en") {
          nameStartMonth = this.monthsEn.find(el => el.id === startMonth).shortName
        }
        // const nameStartMonth = this.months.find(el => el.id === startMonth).shortName
        const time = date.toLocaleTimeString().slice(0,-3);
        str = startDay + ' ' + nameStartMonth + ', ' + time
        return str
      }
    },
    fixDateFormatWithDots (fixedDate) {
      if (fixedDate) {
        let date = new Date(fixedDate)
        let str = ''
        const startDay = String(date.getDate()).padStart(2, '0')
        const startMonth = String(date.getMonth() + 1).padStart(2, '0');
        const startYear = date.getFullYear()
        str = startDay + '.' + startMonth + '.' + startYear
        return str
      }
    },
    fixDateFormatLocale (dateString) {
      let moment = require("moment");
      if (dateString) {
        const date = new Date(dateString);
        const locale = Intl.DateTimeFormat().resolvedOptions().locale;
        moment = moment(date, "YYYY-MM-DDTHH:mm:ss.sssZ", locale);
        return moment.format("DD MMM, HH:mm");
      }
      return null
    },
    fixDateFormatNumbers (fixedDate) {
      if (fixedDate) {
        let date = new Date(fixedDate)
        let str = ''
        const startDay = String(date.getDate()).padStart(2, '0')
        const startMonth = String(date.getMonth() + 1).padStart(2, '0');
        const startYear = date.getFullYear()
        str = startYear + '-' + startMonth + '-' + startDay
        return str
      }
    },

    fixDateFormatTransactions (fixedDateStart, endDay) {
      if (fixedDateStart) {
        let date = new Date(fixedDateStart)
        let str = ''
        str = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0')
        // str += endDay ? 'T23:59:00' : 'T00:00:00'
        str += endDay ? 'T23:59:59' : 'T00:00:00'
        return str
      }
    },

    dateWithTimezone (val) {
      if (val) {
        let date = new Date(val)
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
      }
    }
  }
};

export default fixDateFormat;
