<template>
  <div class="verify-email">
    <div class="verify-email__title">
     {{ $t('verify_email.verify_mail') }}
    </div>
    <div v-if="isRepeat" class="verify-email__button">
      <ButtonElement
        :text="$t('button_props.send_again')"
        @click-button="repeatVerify"
      />
    </div>
    <div v-if="isNotice" class="verify-email__notice">
     {{ $t('verify_email.link_to_verify') }}
    </div>
    <div v-if="isNotice" class="verify-email__return">
      {{ $t('verify_email.go_back') }} <router-link :to="{name: 'Login'}">{{ $t('verify_email.auth') }}</router-link>
    </div>
  </div>
</template>

<script>
import ButtonElement from "../components/elements/ButtonElement";

export default {
  name: 'VerifyEmail',
  components: {
    ButtonElement,
  },
  data () {
    return {
      isRepeat: false,
      isNotice: false,
    }
  },
  mounted () {
    this.checkVerify()
  },
  methods: {
    checkVerify () {
      if (this.$route.query && this.$route.query.queryURL) {
        const url = decodeURI(this.$route.query.queryURL)
        this.$store.dispatch('Auth/verifyEmail', url)
          .then(({data}) => {
            if (data.data.token) {
              this.$store.commit('Auth/auth_success', data.data.token)
              this.$store.dispatch('Auth/getUser',{token:data.data.token } )
            } else {
              this.$router.push('/')
            }
          })
          .catch(err => {
            this.isRepeat = true
            console.log(err)
          })
      } else {
        this.isRepeat = true
      }
    },
    repeatVerify () {
      // this.$store.dispatch('Auth/repeatVerify')
      this.$store.dispatch('Auth/repeatVerifyFromRegistration', { email: this.$route.params.email })
        .then(() => this.isNotice = true)
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style scoped lang="scss">
  .verify-email {
    &__title {
      font-weight: 600;
      margin-bottom: 2rem;
      text-align: center;
    }

    .button {
      padding: 0 1.5rem;
      margin: 0 auto 2rem;
      display: block;
    }

    &__return {
      text-align: center;

      a {
        color: #2D52D2;
      }
    }
  }
</style>
