<template>
  <div class="account-card">
    <div class="account-card__info" :class="{'locale-usa': locale === 'USA'}">
      <div class="account-card__info-name">
        <div class="account-card__info-title">
          <div>{{$t('account_card.title')}}</div>
          <div v-if="isShowAgencyAccountType" class="agency_account_type">{{ agentAccountType }}</div> 
        </div>
        <div
          class="account-card__info-value"
        >
          <a v-if="platform === 'mytarget'" href="https://target.my.com/clients/list" target="_blank">
            {{ fixedName(info.account_name) }}
          </a>
          <span v-else>
            {{ fixedNameVK(info.account_name) }}
          </span>
        </div>
        <div
          v-if="info.description"
          class="account-card__info-description account-card__info-value--bold"
          v-html="info.description"
        />
      </div>
      <div class="account-card__info-balance">
        <div class="account-card__info-title">
          {{$t('account_card.remainder')}}
        </div>
        <div class="account-card__info-description">
          {{ info.balance ? (platform === 'google' || locale==='USA')  
          ? numberWithSpacesFixed(info.balance, 2) 
          : numberWithSpacesCeil(info.balance) 
          : 0 }} 
          {{ currency_symbols[info.service_currency] }}
        </div>
      </div>
      <div class="account-card__info-expense">
        <div class="account-card__info-title">
          {{$t('account_card.expenses_today')}}
        </div>
        <div class="account-card__info-description">
          {{ info.spent 
          ? (platform === 'google'|| locale==='USA') 
          ? numberWithSpacesFixed(info.spent, 2) 
          : numberWithSpacesCeil(info.spent) 
          : 0 }} 
          {{ currency_symbols[info.service_currency] }}
        </div>
      </div>
      <div class="account-card__info-status">
        <div class="account-card__info-title">
          {{$t('account_card.status')}}
        </div>
        <div class="account-card__info-description">
          {{ info.status }}
        </div>
      </div>
      <div class="account-card__info-add">
        <ButtonElement
          v-if="info.status === 'pending' || info.status === 'declined'"
          :text="$t('button_props.verify')"
          view="empty"
          @click-button="checkAccount(info.id)"
        />
        <ButtonElement
          v-if="info.status === 'active'"
          :text="$t('button_props.add')"
          @click-button="fillAccount(info)"
          :disabled="isButtonDisabled"
        />
      </div>
      <!-- v-if="activeAccount && activeAccount.visible_services && (activeAccount.visible_services[platform] || role === 'manager' || role === 'finance')" -->
      <el-dropdown
        v-if="isShowEditDropdown"
        class="account-card__companies-change"
        trigger="click"
        @command="handleCommandForAccount"
      >
        <span class="el-dropdown-link account-card__companies-change__btn">
          <svg width="3" height="17" viewBox="0 0 3 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5ZM3 8.5C3 9.32843 2.32843 10 1.5 10C0.671573 10 0 9.32843 0 8.5C0 7.67157 0.671573 7 1.5 7C2.32843 7 3 7.67157 3 8.5ZM1.5 17C2.32843 17 3 16.3284 3 15.5C3 14.6716 2.32843 14 1.5 14C0.671573 14 0 14.6716 0 15.5C0 16.3284 0.671573 17 1.5 17Z"
              fill="#9BA2AB"/>
          </svg>
        </span>
        <el-dropdown-menu class="account-card__companies-change__list" :class="{'locale-usa': locale === 'USA'}" slot="dropdown">
          <el-dropdown-item
            v-if="platform === 'mytarget'"
            class="account-card__companies-change__list-item"  
          >
            <a href="https://target.my.com/clients/list" target="_blank">{{$t('account_card.go_to_my_target')}}</a>
          </el-dropdown-item>

          <el-dropdown-item
            v-if="platform !== 'yandex' && platform !== 'promopages' && platform !== 'facebook'&& platform !== 'telegram'
            && (this.locale === 'USA' || (platform !== 'google' && (role === 'partner'? activeAccount.services[platform]: activeAccount.visible_services[platform])))" 
            class="account-card__companies-change__list-item"
            :command="{name: 'transfer',}"
          >
            <span>{{$t('account_card.money_back')}} {{ platform }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-if="platform !== 'promopages' && (role==='manager' || role==='finance')"
            class="account-card__companies-change__list-item"
            :command="{
              name: 'info',
              data: { accountName: info.account_name, accountId: info.id, businessAccountId: info.business_account_id, platform }, }"
          >
          {{$t('account_card.check_balance')}}
          </el-dropdown-item>
          <el-dropdown-item
            v-if="role === 'manager' && platform === 'vkontakte'"
            class="account-card__companies-change__list-item"
            :command="{
              name: 'changeProfileLink',
              data: { accountName: info.account_name, accountId: info.id, businessAccountId: info.business_account_id }, }"
          >
          {{$t('account_card.change_profile_link')}}
          </el-dropdown-item>
          <el-dropdown-item
            v-if="role === 'manager' || role === 'finance'"
            class="account-card__companies-change__list-item"
            :command="{ name: 'delete', account: info.id }"
          >
          {{$t('delete')}}
          </el-dropdown-item>
          <el-dropdown-item
            v-if="(role === 'manager' || role === 'finance' || role === 'admin') && (info.status ==='active' || info.status ==='created') && platform === 'telegram'"
            class="account-card__companies-change__list-item"
            :command="{ 
              name: 'setCommission', 
              data: { accountName: info.account_name, accountId: info.id, businessAccountId: info.business_account_id, platform, commission: info.commission_replenishment }, 
            }"
              
          >
          {{$t('account_card.set_commission')}}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- <div v-if="info.commission_replenishment" class="account-card__companies-commission">
          <img src="@/assets/icons/icon-round-dollar.svg" alt=""> <span>{{ info.commission_replenishment }} %</span>
    </div> -->
    <div v-if="info.campaings" class="account-card__companies">
      <div
          class="account-card__companies-count"
          :class="{'account-card__companies-count--open' : showCompanies}"
          @click="getCampaigns"
      >
        <!-- <img src="@/assets/icons/icon-arrow-blue.svg" alt="arrow"> -->
        <img :src="getPic(locale)" alt="icon">
        {{ info.campaings }} {{ declOfNum(info.campaings, [$t('account_card.campaigns.0'), $t('account_card.campaigns.1'), $t('account_card.campaigns.2')]) }}
        <div 
          v-if="platform ==='telegram' && role !=='partner'" 
          class="account-card__add-token"
          @click="openBindingCreativeDialog(info)"
        >Добавить токен</div>
        

      </div>
      <div v-if="showCompanies && campaignList.length" class="account-card__companies-list">
        <el-table
          :data="campaignList"
          style="width: 100%"
        >
          <el-table-column
            width="320"
            prop="name"
            :label="$t('account_card._campaign')"
          />
          <el-table-column
            :label="$t('account_card.total_limit')"
          >
            <template slot-scope="scope">
              {{ platform === 'google' ? numberWithSpacesFixed(scope.row.total_limit, 2) : numberWithSpacesCeil(scope.row.total_limit) }} {{platform === 'google' ? '$' :  currency_symbols[info.service_currency] }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('account_card.spent')"
          >
            <template slot-scope="scope">
              {{ platform === 'google' ? numberWithSpacesFixed(scope.row.spent, 2) : numberWithSpacesCeil(scope.row.spent)  }} {{platform === 'google' ? '$' : currency_symbols[info.service_currency] }}
            </template>
          </el-table-column>
          <el-table-column
            prop="click"
            :label="$t('account_card.clicks')"
          />

          <el-table-column>
            <template slot-scope="scope">
              <el-dropdown
                v-if="isShowCampaignDropdown && scope.row.limit_type && scope.row.limit_type !== 'lifetime'"
                class="account-card__companies-change"
                trigger="click"
                :key="scope.id"
                @command="handleCommand"
              >
                <span class="el-dropdown-link account-card__companies-change__btn">
                  <svg width="3" height="17" viewBox="0 0 3 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5ZM3 8.5C3 9.32843 2.32843 10 1.5 10C0.671573 10 0 9.32843 0 8.5C0 7.67157 0.671573 7 1.5 7C2.32843 7 3 7.67157 3 8.5ZM1.5 17C2.32843 17 3 16.3284 3 15.5C3 14.6716 2.32843 14 1.5 14C0.671573 14 0 14.6716 0 15.5C0 16.3284 0.671573 17 1.5 17Z"
                          fill="#9BA2AB"/>
                    </svg>
                </span>

                <el-dropdown-menu v-if="role !== 'partner'" class="account-card__companies-change__list" slot="dropdown">
                  <el-dropdown-item
                    class="account-card__companies-change__list-item"
                    :class="{'locale-usa':locale==='USA'}"
                    :command="{
                      name: 'open',
                      data: { ...scope.row,  accountId: info.id, businessAccountId: info.business_account_id, platform }, }"
                  >
                    {{$t('account_card.change_limit')}}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- <div v-if="info.commission_replenishment" class="account-card__companies-commission-wrapper">
      <div class="account-card__companies-commission">
          <img src="@/assets/icons/icon-round-dollar.svg" alt=""> <span>{{ info.commission_replenishment }} %</span>
      </div>
    </div> -->
    <div v-if="info.commission_replenishment && platform === 'telegram'" class="account-card__companies-commission">
        <img src="@/assets/icons/icon-round-dollar.svg" alt=""><span>{{ info.commission_replenishment }} %</span>
    </div>
  
    <el-dialog
      class="account-card__popup"
      :visible.sync="dialogVisible"
      width="43.4rem"
      top="0"
      append-to-body
    >
      <div class="account-card__popup-close" :class="{'locale-usa':locale==='USA'}" @click="dialogVisible = false">
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.5572 8.27283C26.2163 7.61382 27.2847 7.61382 27.9437 8.27283C28.6027 8.93184 28.6027 10.0003 27.9437 10.6593L10.4428 28.1602C9.78383 28.8192 8.71536 28.8192 8.05635 28.1602C7.39734 27.5012 7.39735 26.4327 8.05635 25.7737L25.5572 8.27283Z" fill="#BFC2C6"/>
          <path d="M27.9437 25.7736C28.6027 26.4326 28.6027 27.501 27.9437 28.16C27.2847 28.819 26.2162 28.819 25.5572 28.16L8.05634 10.6591C7.39733 10.0001 7.39733 8.93167 8.05634 8.27266C8.71535 7.61365 9.78382 7.61365 10.4428 8.27266L27.9437 25.7736Z" fill="#BFC2C6"/>
        </svg>
      </div>

      <div class="account-card__popup-inner" v-loading="loading">
        <div class="account-card__popup-title">{{$t('account_card.refund')}}</div>
        <div class="account-card__popup-text">{{$t('account_card.transfer_amount')}}</div>

        <div
            class="form-input account-card__popup-input"
            :class="
          [
            {'form-input--full' : returnAmount},
            {'form-input--disabled' : returnAmountFull},
          ]"
        >
          <input v-model="returnAmount" type="text" :disabled="returnAmountFull">
          <span class="form-input__label">
            {{$t('account_card.total')}}
          </span>
        </div>
        <SwitchButton :label="$t('account_card.total_amount')" @change="(val) => { returnAmountFull = val }" />

        <div class="account-card__popup-btns" :class="{'locale-usa': locale === 'USA'}">
          <button class="account-card__popup-btn" :disabled="isDisabled" @click="transferFromAccount">{{$t('account_card.transfer')}}</button>
          <button class="account-card__popup-btn account-card__popup-btn--cancel" @click="dialogVisible = false">{{$t('account_card.cancel')}}</button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SwitchButton from "@/components/elements/SwitchElement";
import ButtonElement from "@/components/elements/ButtonElement";
import numberWithSpacesCeil from '@/mixin/numberWithSpaces';
import numberWithSpacesFixed from '@/mixin/numberWithSpaces';
// import { getRepresentativeAccess } from "@/utils/helpers";

export default {
  name: 'AccountCard',
  props: {
    platform: {
      type: String,
      default: '',
    },
    info: {
      type: Object,
      default: null,
    },
  },
  components: {
    ButtonElement,
    SwitchButton
  },
  mixins: [numberWithSpacesCeil, numberWithSpacesFixed],
  data () {
    return {
      locale: process.env.VUE_APP_LOCALE,
      showCompanies: false,
      isData: false,
      isCompanies: false,
      dialogVisible: false,
      campaignList: [],
      isDisabled: false,
      loading: false,
      returnAmount: null,
      returnAmountFull: false,
      currency_symbols: {
        'RUB': '₽',
        'USD': '$',
        'EUR': '€',
        'PLN': 'zł',
      }
    }
  },
  computed: {
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    role() {
      return this.$store.state.Auth.role
    },
    checkLimit() {
      return (this.info && Object.prototype.hasOwnProperty.call(this.info, 'limit_type') && this.info.limit_type === null)
    },
    agentAccountType() {
      if(this.platform === 'mytarget') {
        return  this.info.agency_account_type==='default'?'Gray':this.info.agency_account_type==='profitads'?'Profitads':this.info.agency_account_type==='white'?'AM':''
      }
      if(this.platform === 'yandex') {
        return  this.info.agency_account_type==='default'?'AM':this.info.agency_account_type==='profitads'?'Profitads':''
      }
      if(this.platform === 'vkontakte') {
        return  this.info.agency_account_type==='default'?'AM':this.info.agency_account_type==='profitads'?'Profitads':''
      }
      if(this.platform === 'vkads') {
        return  this.info.agency_account_type==='default'?'AM':this.info.agency_account_type==='profitads'?'Profitads':''
      }
      if(this.platform === 'google') {
        return  this.info.agency_account_type==='default'?'DE Google':this.info.agency_account_type==='easystart'?'DE GoogleES':''
      }
      if(this.platform === 'tiktok') {
        if(this.locale === "RU") {
          return  this.info.agency_account_type==='default'?'AM':this.info.agency_account_type==='profitads'?'Profitads':''
        }
        if(this.locale === "USA") {
          return  this.info.agency_account_type==='default'?'DE TikTok':this.info.agency_account_type==='am'?'AM TikTok':''
        }
      }
      if(this.platform === 'facebook') {
        return  this.info.agency_account_type === 'default'?'DE MetaUSD':this.info.agency_account_type==='digital_pln'?'DE MetaPLN':''
      }
      return ''
    },
    isShowAgencyAccountType() {
      switch (this.locale) {
        case 'USA':
          if(this.platform === 'yandex' || this.platform === 'vkads') {
           return false 
          }
          break;
        case 'RU':
          if(this.platform === 'google' || 
            this.platform === 'promopages' || 
            this.platform === 'meta' || 
            this.platform === 'tiktok'||
            this.platform === 'telegram'
            ) {
            return false
          }
          break; 
      }
      return true
    },
    isButtonDisabled() {
      if(this.locale === 'USA') {
        if(this.activeAccount && (this.activeAccount.is_currency === false && this.info.service_currency !== this.localCurrency)) {
          return true
        } else {
          if(this.role !=='partner') {
            return  !this.activeAccount.visible_services[this.platform] || this.platform ==='promopages'
          } else {
            return !this.isRepresentativeAllowed || this.platform ==='promopages'
          }
        }
      } else {
        if(this.role !=='partner') {
          return  !this.activeAccount.visible_services[this.platform] || this.platform ==='promopages'
        } else {
          return !this.isRepresentativeAllowed || this.platform ==='promopages'
        }
      }
    },
    user() {
      return this.$store.state.Auth.user
    },
    businessAccountInfo() {
      return this.$store.state.Representatives.representativeBAInfo
    },
    isRepresentativeAllowed() {
        const permissionString = `advert.${this.platform}.finance`
        const isAllowed = this.businessAccountInfo.permissions.includes(permissionString)
        return isAllowed
    },
    isShowEditDropdown() {
      let condition
      if(this.role ==='partner') {
        condition = this.activeAccount && this.activeAccount.services.length > 0 && this.activeAccount.services.includes(this.platform)
      } else {
        condition = this.activeAccount && this.activeAccount.visible_services && (this.activeAccount.visible_services[this.platform] || this.role === 'manager' || this.role === 'finance')
      }
      return condition
    },
    isShowCampaignDropdown() {
      const condition = this.role === 'partner' ? this.activeAccount.services[this.platform] : this.activeAccount.visible_services[this.platform]
      return condition
    },
    localCurrency() {
      return this.locale === 'USA' ? 'USD' : 'RUB'
    }
  },
  methods: {
    getPic(locale) {
      if(locale==='RU') {
        return  require("@/assets/icons/icon-arrow-blue.svg")
      } else {
        return require("@/assets/icons/icon-arrow-red.svg")
      }
    },

    getCampaigns() {
      this.showCompanies =!this.showCompanies
      if (!this.campaignList.length) {
        if(this.role !=='partner') {
          this.$store.dispatch('Ad/getCampaigns', { account_id: this.info.id })
          .then((data) => {
              this.campaignList = data.data.data
          })
        } else {
          this.$store.dispatch('Ad/getRepresentativeCampaigns', { account_id: this.info.id, ba_id: this.activeAccount.id, service: this.platform, user_id: this.user.id })
          .then((data) => {
            this.campaignList = data.data.data
          })
        }
      }
    },
    checkAccount(id) {
      const data = {
        service: this.platform,
        business_account_id: this.activeAccount.id,
        account_id: id
      }
      this.$store.dispatch('Ad/checkAccount', data)
      .then((data) => {
        if (data.data.data.error) {
          this.$alert(data.data.data.error, {
            confirmButtonText: 'OK',
            customClass: `${this.locale==='USA'?'locale-usa':'locale-ru'}`
          });
        }
        this.$emit('check-status')
      })
    },
    fillAccount(account) {
      this.$store.commit('Balance/setData', { label: 'filledAccount', data: account })
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'Deposit' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    fixedName(name) {
      if (name.length > 20) {
        return name.substring(0, 18) + '...'
      } else {
        return name
      }
    },
    fixedNameVK(name) {
      if (name.startsWith('http')) {
        return name.split('/')[3] || ''
      } else {
        return name
      }
    },
    openEditingLimitDialog(data) {
      this.$store.commit('Company/setData', { label: 'companyData', data })
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'EditingLimit' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    openFundsInfoDialog(data) {
      this.$store.commit('Company/setData', { label: 'companyData', data })
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'FundsInfo' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    openSetCommissionDialog(data) {
      this.$store.commit('Company/setData', { label: 'companyData', data })
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'SetCommission' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    openBusinessAccountClientsDialog(data) {
      this.$store.commit('Ad/setData', { label: 'currentNewUser', data })
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'BusinessAccountClients' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    openTransferFromAccount() {
      this.dialogVisible = true
    },
    transferFromAccount() {
      if (!this.returnAmountFull && !this.returnAmount) {
        this.$notify({
          message: this.$t('account_card.enter_amount'),
          type: 'error'
        });
        return false
      }
      this.isDisabled = true
      this.loading = true
      const data = {
        service: this.info.service,
        account_id: this.info.id,
      }
      if (!this.returnAmountFull && this.returnAmount) {
        data.amount = this.returnAmount
      }
      this.$store.dispatch('Ad/transferFromAccountToBusinessAccount', { data })
      .then(() => {
        this.isDisabled = false
        this.loading = false
        this.dialogVisible = false
        this.$notify({
          message: this.$t('account_card.money_returned'),
          type: 'success'
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000)
      }).catch(() => {
        this.dialogVisible = false
        this.isDisabled = false
        this.loading = false
        this.$notify({
          message: this.$t('account_card.try_later'),
          type: 'error'
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000)
      })
    },
    handleCommand(command) {
      if (command.name === 'open') {
        this.openEditingLimitDialog(command.data);
      }
    },
    handleCommandForAccount(command) {
      if (command.name === 'delete') {
        const data = {
          data: { account_id: command.account },
          token: localStorage.getItem('token'),
        }
        this.$store.dispatch('Ad/deleteAccount', data)
        .then(() => {
          this.$notify({
            message: this.$t('account_card.message_del'),
            type: 'success'
          });
          this.$emit('check-status')
        })
        .catch(() => {
          this.$notify({
            message: this.$t('account_card.message_err'),
            type: 'error'
          });
        })
      } else if (command.name === 'transfer') {
        this.openTransferFromAccount()
      } else if (command.name === 'info') {
        this.openFundsInfoDialog(command.data);
      } else if (command.name === 'changeProfileLink') {
        this.openBusinessAccountClientsDialog(command.data);
      } else if (command.name === 'setCommission') {
        this.openSetCommissionDialog(command.data);
      }
    },
    declOfNum (number, titles) {
      const cases = [2, 0, 1, 1, 1, 2]
      return titles[ (number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5] ]
    },
    openBindingCreativeDialog() {
      this.$store.dispatch('Balance/setFilledAccount', this.info)
      this.$router.push({ path: '/creatives', query: { platform: 'telegram' }})

      // this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'BindCreative' })
      // this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    // getRepresentativeAccess(permissionToCheck){
    //   return getRepresentativeAccess(permissionToCheck, this.businessAccountInfo, this.role)
    // },
  },
}

</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.account-card {
  background: $basicWhite;
  box-shadow: 0px 4px 12px rgba(192, 192, 192, 0.12);
  border-radius: 6px;
  padding: 3rem 2rem;
  position: relative;
  min-height: 151px;

  &__companies-commission-wrapper {
    display: flex;
  }

  &__companies-commission {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
    gap: 2rem;
    padding: 4px 6px;
    border: 1px solid #9BA2AB;
    border-radius: 4px;
    position: absolute;
    right: 3%;
    top: 117px;
    line-height: 0;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &.locale-usa {
      font-family: 'Gilroy';
      font-weight: 500;
    }
    &-name {
      width: 25rem;
      > .account-card__info-title {
        display: grid;
        grid-template-columns: minmax(0, max-content) minmax(0, max-content);
        grid-column-gap: 1.4rem;
        align-items: center;
        .agency_account_type {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #333;
          padding: 7px 8px;
          background: #F5F7FA;
          border-radius: 4px;
        }
      }
    }

    &-title {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #aaa;
      margin-bottom: 0.5rem;
    }

    &-description {
      font-size: 2rem;
      line-height: 2.4rem;
    }

    &-value {
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #9BA2AB;

      &--bold {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #000;
      }
    }

    &-add {
      width: 12.5rem;

      .button {
        height: 100%;
      }
    }

    .button{
      height: 40px;
    }
  }
  &__add-token {
    margin-left: 5rem;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary);
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__companies {
    margin-top: 2.5rem;
    // display: flex;
    
    &-count {
      font-size: 16px;
      line-height: 19px;
      color: var(--primary);
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        margin-right: 1rem;
        transition: 0.3s ease-out;
      }

      &--open {
        img {
          transform: rotate(180deg);
        }
      }
    }

    &-list {
      margin-top: 3rem;
    
    }

    &-change {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &__btn {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        svg {
          display: block;
          height: auto;

          path {
            transition: fill .4s linear;
          }
        }

        &:hover {

          svg {

            path {
              fill: var(--primary);
            }
          }
        }
      }

      &__list {
        background: #FFFFFF;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
        border: none;
        border-radius: 3px;
        padding: 0;
        &.locale-usa {
          .account-card__companies-change__list-item:hover {
              color: #FF7F50;
          }
        }

        &-item {
          font-size: 1.6rem;
          line-height: 1.9;
          color: $basicBlack;
          transition: color .4s linear, background-color .4s linear;
          border-bottom: 1px solid $gray5;
          padding: 10px 15px;

          &.locale-usa {
            &:hover {
            color:  #FF7F50;
            }
          }

          &:hover {
            color: $primary;
            background-color: $gray9;
          }

          &:last-of-type {
            border-bottom: none;
          }
        }
      }
    }
  }
  &__popup-btns {
    &.locale-usa {
      .account-card__popup-btn {
        border-color: #FF7F50;
        background-color: #FF7F50;
        &--cancel {
          background-color: transparent;
          color: #FF7F50;
          &:hover {
            background-color: #FF7F50;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}

.account-card__popup-input{
  margin-bottom: 1.2rem;
}
.account-card__popup-btns{
  flex-wrap: wrap;
  margin-top: 5.6rem;
}

@include below(1441px) {
  .account-card__companies-commission {
    top: 103px;
  }
}

@include below(769px) {
  .account-card__companies-commission {
    position: static;
    margin-top: 1rem;
  }
  .account-card__info{
    flex-wrap: wrap;
  }
  .account-card__info-name{
    width: 100%;
    margin-bottom: 10px;
  }
  .account-card__info-add{
    width: calc(100% - 70px);
    margin-top: 10px;
    .button{
      height: 40px;
      width: 100%;
    }
  }
  .account-card__companies-change {
    margin-top: 10px;
    width: 20px;
    height: 40px;
  }
  .account-card__popup-btns{
    flex-wrap: wrap;
  }
  .account-card__popup-btn{
    width: 100%;
    margin-right: 0;
    &:not(:last-child){
      margin-bottom: 10px;
    }
  }
}
</style>
